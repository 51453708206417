import { createContext, useEffect, useState } from 'react';
import { postLogIn, postLogOut } from '../api/auth';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken')
  );
  const [permissions, setPermissions] = useState(
    localStorage.getItem('permissions') || []
  );
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('permissions', permissions);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('permissions');
    }
  }, [accessToken, permissions]);

  const logIn = async ({ email, password }) => {
    const {
      data: {
        accessToken,
        administrator: { permissions, ...admin },
      },
    } = await postLogIn({ email, password });

    setAccessToken(accessToken);
    setAdmin(admin);
    setPermissions(permissions);
  };

  const logOut = async () => {
    try {
      if (window.navigator.onLine) {
        await postLogOut();
      }
    } finally {
      setAccessToken(null);
      setPermissions(null);
      setAdmin(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        admin,
        permissions,
        logIn,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
