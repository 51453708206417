import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import CustomAppBarFilter from '../components/shared/CustomAppBarFilter';
import CustomInput from '../components/shared/CustomInput';
import OrderCards from '../components/OrderCards';
import { getOrders } from '../api/order';
import dayjs from 'dayjs';
import OrderSummary from '../components/OrderSummary';
import OrderFilters from '../components/OrderFilters';

const Order = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);

  const [keyword, setKeyword] = useState(searchParams.get('keyword') || '');
  const [savedKeyword, setSavedKeyword] = useState(
    searchParams.get('keyword') || ''
  );
  const debounceKeyword = useRef(
    debounce((nextValue) => {
      setSavedKeyword(nextValue);
    }, 1000)
  ).current;

  const handleChangeKeyword = (event) => {
    const { value } = event.target;
    value !== ''
      ? updatedSearchParams.set('keyword', value)
      : updatedSearchParams.delete('keyword');
    setSearchParams(updatedSearchParams, { replace: true });
    setKeyword(value);
    debounceKeyword(value);
  };

  const handleClearKeyword = () => {
    updatedSearchParams.delete('keyword');
    setSearchParams(updatedSearchParams, { replace: true });
    setKeyword('');
    debounceKeyword('');
  };

  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(30, 'd').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    dayjs(new Date()).format('YYYY-MM-DD')
  );

  const [ordersStatus, setOrdersStatus] = useState([]);

  const [ordersType, setOrdersType] = useState([]);

  const [ordersSort, setOrdersSort] = useState('desc');

  const [orders, setOrders] = useState([]);
  const [ordersSummary, setOrdersSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);

  const fetchOrders = async ({
    keyword,
    sortAmountDirection,
    orderTypeIds,
    statuses,
    startDate,
    endDate,
  }) => {
    try {
      setIsLoading(true);
      const {
        data: {
          histories: { invoiceCount, orderCount, totalAmount, invoices },
          nextPageUrl,
        },
      } = await getOrders({
        startDate,
        endDate,
        statuses,
        keyword,
        sortAmountDirection,
        orderTypeIds,
      });
      setOrders(invoices);
      setOrdersSummary({ invoiceCount, orderCount, totalAmount });

      if (nextPageUrl) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders({
      keyword: savedKeyword || null,
      statuses: ordersStatus,
      sortAmountDirection: ordersSort || null,
      orderTypeIds: ordersType,
      startDate,
      endDate,
    });
  }, [savedKeyword, ordersSort, ordersType, ordersStatus, startDate, endDate]);

  const SearchField = (
    <CustomInput
      placeholder="Invoice"
      fullWidth
      value={keyword}
      onChange={handleChangeKeyword}
      startAdornment={<SearchIcon />}
      endAdornment={
        keyword !== '' && (
          <CloseIcon onClick={handleClearKeyword} sx={{ cursor: 'pointer' }} />
        )
      }
      sx={{ '& .MuiOutlinedInput-input': { paddingBlock: '8px' } }}
    />
  );

  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarFilter filters={[SearchField]} />
      <Container maxWidth="lg" disableGutters>
        <Box sx={{ p: 2 }}>
          <Box>
            <OrderFilters
              date={{ startDate, setStartDate, endDate, setEndDate }}
              status={{ ordersStatus, setOrdersStatus }}
              sort={{ ordersSort, setOrdersSort }}
              type={{ ordersType, setOrdersType }}
            />

            <OrderSummary summary={ordersSummary} />

            {isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '500px',
                  transform: 'scaleX(-1)',
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {!isLoading && orders.length === 0 && (
              <Typography sx={{ fontSize: '14px', mt: 2 }}>
                No orders found
              </Typography>
            )}

            {!isLoading && orders.length > 0 && (
              <OrderCards
                orders={orders}
                setOrders={setOrders}
                hasMore={hasMore}
                setHasMore={setHasMore}
                keyword={savedKeyword}
                startDate={startDate}
                endDate={endDate}
              />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default Order;
