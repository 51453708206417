import axios from 'axios';
import { adminAxios } from '../helpers/api';

export const addArea = (data) => adminAxios.post('outlet-room/add', data);
export const updateArea = (idArea, data) =>
  adminAxios.put(`outlet-room/${idArea}`, data);
export const deleteArea = (idArea) =>
  adminAxios.delete(`outlet-room/${idArea}`);
export const getDetailArea = (id) => adminAxios.get(`outlet-room/${id}`);

// ! images

export const uploadImageArea = (data) => {
  const accessToken = localStorage.getItem('accessToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/outlet-room/images`,
    data: data,
    headers: {
      Access: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// ! videos
export const uploadVideoRoom = (data) => {
  const accessToken = localStorage.getItem('accessToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/outlet-room/videos`,
    data: data,
    headers: {
      Access: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
