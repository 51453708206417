import { Close as CloseIcon } from '@mui/icons-material';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import CustomCard from './shared/CustomCard';
import CustomDialog from './shared/CustomDialog';
import CustomInput from './shared/CustomInput';
import CustomButton from './shared/CustomButton';
import { patchStationName } from '../api/station';
import { getStations } from '../api/station';
import InfiniteScroll from 'react-infinite-scroll-component';

const StationCards = ({
  stations,
  fetchStations,
  isLoading,
  setIsLoading,
  keyword,
  hasMore,
  setStations,
  setHasMore,
  permissions,
}) => {
  const theme = useTheme();
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [selectedStation, setSelectedStation] = useState({
    id: null,
    name: '',
  });
  const [errorStationName, setErrorStationName] = useState(false);

  useEffect(() => {
    setErrorStationName(false);
    if (!isOpenEdit)
      setSelectedStation({
        id: null,
        name: '',
      });
  }, [isOpenEdit]);

  const handleEditStation = async (event) => {
    event.preventDefault();

    if (selectedStation?.name !== '') {
      try {
        setIsLoading(true);
        await patchStationName({
          name: selectedStation?.name,
          id: selectedStation?.id,
        });
        await fetchStations({ keyword });
        setIsOpenEdit(!isOpenEdit);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorStationName(true);
    }
  };

  const [page, setPage] = useState(2);

  const fetchMoreStations = async ({ keyword, page }) => {
    const {
      data: { stations: newStations, nextPageUrl },
    } = await getStations({
      keyword,
      page,
    });

    setStations([...stations, ...newStations]);

    if (nextPageUrl) {
      setHasMore(true);
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={stations.length}
        next={() => fetchMoreStations({ keyword, page })}
        hasMore={hasMore}
        loader={
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
              mt: 4,
            }}
          >
            <CircularProgress size={20} />
          </Box>
        }
        style={{ overflow: 'visible' }}
      >
        <Grid container spacing={2}>
          {stations.map((station, index) => (
            <Grid item xs={6} key={station.id}>
              <CustomCard
                sx={{ height: 1, cursor: 'pointer' }}
                onClick={() => {
                  if (permissions.includes('edit-station')) {
                    setSelectedStation(station);
                    setIsOpenEdit(!isOpenEdit);
                  }
                }}
              >
                <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
                  {station.name}
                </Typography>
              </CustomCard>
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>

      <CustomDialog
        isOpen={isOpenEdit}
        onClose={() => setIsOpenEdit(!isOpenEdit)}
      >
        <Box component='form' onSubmit={handleEditStation}>
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>Edit Station</Typography>
            <CloseIcon
              sx={{ fontSize: '1.25rem' }}
              onClick={() => {
                setIsOpenEdit(!isOpenEdit);
              }}
            />
          </DialogTitle>
          <DialogContent>
            <CustomInput
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  boxShadow:
                    errorStationName && `0 0 4px 0 ${theme.palette.error.main}`,
                },
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    boxShadow:
                      errorStationName &&
                      `0 0 4px 0 ${theme.palette.error.main}`,
                  },
                },
              }}
              fullWidth
              label='Name'
              placeholder='Name'
              value={selectedStation?.name}
              onChange={(event) => {
                let tempStation = { ...selectedStation };
                tempStation.name = event.target.value;
                setSelectedStation(tempStation);
                setErrorStationName(false);
              }}
              error={errorStationName}
              helperText={errorStationName && 'required'}
            />
          </DialogContent>
          <DialogActions>
            <CustomButton
              disabled={isLoading}
              type='submit'
              variant='contained'
            >
              Submit
            </CustomButton>
          </DialogActions>
        </Box>
      </CustomDialog>
    </>
  );
};
export default StationCards;
