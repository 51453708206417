import { adminAxios } from '../helpers/api';

export const getBanners = () => adminAxios.get('outlet-banners');

export const getBannerById = ({ id }) => adminAxios.get(`outlet-banners/${id}`);

export const patchBannerById = ({ id, data }) =>
  adminAxios.patch(`outlet-banners/${id}`, data);

export const postBannerImage = (data) =>
  adminAxios.post('outlet-banners/images', data);

export const postBanner = (data) => adminAxios.post('outlet-banners', data);

export const deleteBannerById = ({ id }) =>
  adminAxios.delete(`outlet-banners/${id}`);
