import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

const CustomInput = ({ fullWidth, error, helperText, ...props }) => {
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <OutlinedInput {...props} />
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
export default CustomInput;
