import { Box } from '@mui/material';

const CustomCard = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        p: 1.5,
        borderRadius: '12px',
        boxShadow: '0 0 6px 0 #85828240',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
export default CustomCard;
