import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomAppBar from '../components/shared/CustomAppBar';
import BannerCards from '../components/BannerCards';
import { getBanners } from '../api/banner';
import CustomFab from '../components/shared/CustomFab';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import EmptyBannerImage from '../assets/images/empty-banner-image.png';

const Banner = ({ permissions }) => {
  const navigate = useNavigate();

  const [banners, setBanners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        setIsLoading(true);
        const { data: orderTypes } = await getBanners();
        setBanners(orderTypes);
      } catch {
      } finally {
        setIsLoading(false);
      }
    };

    fetchBanners();
  }, []);

  return (
    <Box>
      <CustomAppBar title='Banner' />
      <Container maxWidth='lg' disableGutters>
        <Box sx={{ p: 2 }}>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '500px',
                transform: 'scaleX(-1)',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {!isLoading && banners.length === 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 6rem)',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Box
                component='img'
                src={EmptyBannerImage}
                sx={{
                  width: 0.2,
                  aspectRatio: '1/1',
                }}
              />
              <Typography
                sx={{ fontSize: '0.75rem', textAlign: 'center', px: 4 }}
              >
                You haven’t uploaded any banners yet. Click + icon to upload a
                banner.
              </Typography>
            </Box>
          )}

          {!isLoading && banners.length > 0 && (
            <BannerCards
              banners={banners}
              setBanners={setBanners}
              permissions={permissions}
            />
          )}
        </Box>
        {permissions.includes('add-outlet-banner') && banners.length < 5 && (
          <CustomFab
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            color='primary'
            icon={<AddIcon />}
            onClick={() => navigate('add')}
          />
        )}
      </Container>
    </Box>
  );
};
export default Banner;
