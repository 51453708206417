import React from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';
import CustomCard from './shared/CustomCard';
import { numberWithCommas } from '../helpers/general';

const OrderProductDetail = ({ orderDetails }) => {
  return (
    <Box>
      <Typography sx={{ fontSize: '16px', fontWeight: 600, mb: 1 }}>
        Orders
      </Typography>

      <CustomCard sx={{ p: 0 }}>
        {orderDetails?.orders?.map((productItem, index) => {
          return (
            <Box key={index}>
              {index ? <Divider /> : null}
              <Grid container columnSpacing={2} sx={{ p: 1.5 }}>
                <Grid item xs={2.5}>
                  <Box
                    component='img'
                    src={productItem?.product?.imageUrl}
                    sx={{
                      width: 1,
                      borderRadius: '12px',
                      aspectRatio: '1/1',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                    {productItem?.name}
                  </Typography>
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 600, mt: 0.3 }}
                  >
                    {productItem?.quantity}x
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs='auto'
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                    Rp
                    {numberWithCommas(
                      productItem?.price * productItem?.quantity
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </CustomCard>
    </Box>
  );
};

export default OrderProductDetail;
