import { Routes, Route, Navigate } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import useAuth from './hooks/useAuth';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import OrderType from './pages/OrderType';
import Station from './pages/Station';
import ProductCategory from './pages/ProductCategory';
import { useMediaQuery } from '@mui/material';
import ProductCategoryEdit from './pages/ProductCategoryEdit';
import ProductCategoryAdd from './pages/ProductCategoryAdd';
import Product from './pages/Product';
import ProductEdit from './pages/ProductEdit';
import ProductAdd from './pages/ProductAdd';
import OperatingHour from './pages/OperatingHour';
import PaymentMethod from './pages/PaymentMethod';
import Banner from './pages/Banner';
import BannerEdit from './pages/BannerEdit';
import BannerAdd from './pages/BannerAdd';
import Order from './pages/Order';
import OrderDetail from './pages/OrderDetail';
import AreaMenu from './pages/Area';
import DetailAreaMenu from './pages/Area/DetailArea/DetailArea';
import AddTableMenu from './pages/Area/Table/Add/AddTable';
import EditTable from './pages/Area/Table/Edit/EditTable';
import SubCategoryMenu from './pages/SubCategory';
import AddSubCategoryMenu from './pages/SubCategory/Add/AddSubCategory';
import EditSubCategoryMenu from './pages/SubCategory/Edit/EditSubCategory';
import AddArea from './pages/Area/AddArea/AddArea';
import EditAreaMenu from './pages/Area/EditArea/EditArea';

function App() {
  const { accessToken, permissions } = useAuth();
  const matches = useMediaQuery('(max-width:444px)');

  return (
    <Routes>
      <Route index element={<Login />} />

      {accessToken && (
        <Route element={<RequireAuth />}>
          {permissions.includes('view-outlet-invoice') && (
            <Route path="order">
              <Route index element={<Order />} />
              <Route path=":orderId" element={<OrderDetail />} />
            </Route>
          )}
          {permissions.includes('view-station') && (
            <Route
              path="/station"
              element={<Station permissions={permissions} />}
            />
          )}

          {permissions.includes('view-outlet-area') && (
            <Route path="/area">
              <Route
                index
                element={
                  <AreaMenu matches={matches} permissions={permissions} />
                }
              />
              <Route
                path="addArea"
                element={
                  <AddArea matches={matches} permissions={permissions} />
                }
              />

              <Route path="editArea/:idArea"
                element={<EditAreaMenu matches={matches} permissions={permissions} />}
              />

              <Route
                path="detail/:idArea"
                element={
                  <DetailAreaMenu matches={matches} permissions={permissions} />
                }
              />

              <Route
                path=":idArea/editTable/:editTableId"
                element={
                  <EditTable matches={matches} permissions={permissions} />
                }
              />

              <Route
                path=":idArea/addTable"
                element={
                  <AddTableMenu matches={matches} permissions={permissions} />
                }
              />
            </Route>
          )}

          {permissions.includes('view-product-category') && (
            <Route path="/product-category">
              <Route
                index
                element={
                  <ProductCategory
                    matches={matches}
                    permissions={permissions}
                  />
                }
              />
              {permissions.includes('add-product-category') && (
                <Route
                  path="add"
                  element={<ProductCategoryAdd matches={matches} />}
                />
              )}

              <Route
                path="detail/:idCategory"
                element={
                  <SubCategoryMenu
                    matches={matches}
                    permissions={permissions}
                  />
                }
              />

              <Route
                path="edit/:productCategoryId"
                element={
                  <ProductCategoryEdit
                    matches={matches}
                    permissions={permissions}
                  />
                }
              />

              <Route
                path="addSubCategory"
                element={
                  <AddSubCategoryMenu
                    matches={matches}
                    permissions={permissions}
                  />
                }
              />

              <Route
                path="editSubCategory/:idSubCategory"
                element={
                  <EditSubCategoryMenu
                    matches={matches}
                    permissions={permissions}
                  />
                }
              />
            </Route>
          )}
          {permissions.includes('view-product') && (
            <Route path="/product">
              <Route
                index
                element={
                  <Product matches={matches} permissions={permissions} />
                }
              />
              <Route
                path="edit/:productId"
                element={
                  <ProductEdit matches={matches} permissions={permissions} />
                }
              />

              {permissions.includes('add-product') && (
                <Route path="add" element={<ProductAdd matches={matches} />} />
              )}
            </Route>
          )}
          {permissions.includes('view-outlet-payment-method') && (
            <Route path="/payment-method">
              <Route
                index
                element={<PaymentMethod permissions={permissions} />}
              />
            </Route>
          )}
          {permissions.includes('view-outlet-banner') && (
            <Route path="/banner">
              <Route index element={<Banner permissions={permissions} />} />
              {permissions.includes('edit-outlet-banner') && (
                <Route path=":bannerId/edit" element={<BannerEdit />} />
              )}

              {permissions.includes('add-outlet-banner') && (
                <Route path="add" element={<BannerAdd />} />
              )}
            </Route>
          )}
          {permissions.includes('view-outlet-order-type') && (
            <Route
              path="/order-type"
              element={<OrderType permissions={permissions} />}
            />
          )}
          {permissions.includes('view-outlet-operating-hour') && (
            <Route path="/operating-hour">
              <Route
                index
                element={<OperatingHour permissions={permissions} />}
              />
            </Route>
          )}
        </Route>
      )}

      {!accessToken && <Route path="*" element={<Navigate to="/" replace />} />}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
