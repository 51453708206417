import React, { useState } from 'react';
import { Box, Switch, TextField, Typography, Alert } from '@mui/material';
import CustomCard from './shared/CustomCard';
import {
  dayName,
  operatingHourStatusBoolean,
  operatingHourStatusString,
} from '../helpers/general';
import {
  patchOperatingHourStatus,
  patchOperatingHourTime,
} from '../api/operatingHour';

const OperatingHourCards = ({
  operatingHours,
  setOperatingHours,
  permissions,
}) => {
  const handleChangeStatus = async (operatingHour) => {
    let tempOperatingHours = [...operatingHours];
    let tempOperatingHour = tempOperatingHours.find(
      (tempOperatingHour) => tempOperatingHour.id === operatingHour.id
    );
    tempOperatingHour.status = operatingHourStatusString(
      !operatingHourStatusBoolean(tempOperatingHour.status)
    );
    setOperatingHours(tempOperatingHours);

    await patchOperatingHourStatus({
      id: operatingHour.id,
      data: { status: tempOperatingHour.status },
    });
  };

  const handleChangeOpenTime = async (nextValue, operatingHour) => {
    nextValue += ':00';

    let tempOperatingHours = [...operatingHours];
    let tempOperatingHour = tempOperatingHours.find(
      (tempOperatingHour) => tempOperatingHour.id === operatingHour.id
    ).outletDailyOperatingHours[0];
    tempOperatingHour.openAt = nextValue;

    if (tempOperatingHour.closeAt > tempOperatingHour.openAt) {
      await patchOperatingHourTime({
        id: operatingHour.id,
        data: {
          open_at: tempOperatingHour.openAt,
          close_at: tempOperatingHour.closeAt,
        },
      });
    }

    setOperatingHours(tempOperatingHours);
  };

  const handleChangeCloseTime = async (nextValue, operatingHour) => {
    nextValue += ':00';

    let tempOperatingHours = [...operatingHours];
    let tempOperatingHour = tempOperatingHours.find(
      (tempOperatingHour) => tempOperatingHour.id === operatingHour.id
    ).outletDailyOperatingHours[0];
    tempOperatingHour.closeAt = nextValue;

    if (tempOperatingHour.closeAt > tempOperatingHour.openAt) {
      await patchOperatingHourTime({
        id: operatingHour.id,
        data: {
          open_at: tempOperatingHour.openAt,
          close_at: tempOperatingHour.closeAt,
        },
      });
    }

    setOperatingHours(tempOperatingHours);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {operatingHours.map((operatingHour, index) => {
        let openTime = operatingHour.outletDailyOperatingHours[0].openAt;
        let closeTime = operatingHour.outletDailyOperatingHours[0].closeAt;

        openTime = openTime.split(':').splice(0, 2).join(':');
        closeTime = closeTime.split(':').splice(0, 2).join(':');

        return (
          <CustomCard key={index}>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}
            >
              <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>
                {dayName(operatingHour.day)}
              </Typography>
              {permissions.includes('adjust-outlet-operating-hour-status') && (
                <Switch
                  size='small'
                  checked={operatingHourStatusBoolean(operatingHour.status)}
                  onChange={() => handleChangeStatus(operatingHour)}
                />
              )}
            </Box>
            {operatingHourStatusBoolean(operatingHour.status) && (
              <Box sx={{ display: 'flex', gap: 2, mt: 1.5 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '10px',
                      color: 'info.main',
                    }}
                  >
                    Open
                  </Typography>
                  <TextField
                    disabled={
                      !permissions.includes('edit-outlet-operating-hour')
                    }
                    variant='standard'
                    type='time'
                    value={openTime}
                    onChange={(event) =>
                      handleChangeOpenTime(event.target.value, operatingHour)
                    }
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '10px',
                      color: 'info.main',
                    }}
                  >
                    Close
                  </Typography>
                  <TextField
                    disabled={
                      !permissions.includes('edit-outlet-operating-hour')
                    }
                    variant='standard'
                    type='time'
                    value={closeTime}
                    onChange={(event) =>
                      handleChangeCloseTime(event.target.value, operatingHour)
                    }
                  />
                </Box>
              </Box>
            )}

            {closeTime <= openTime && (
              <Alert
                sx={{
                  mt: 1.5,
                  fontSize: '12px',
                  '& .MuiAlert-icon': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 0,
                    mr: 1,
                  },
                  display: 'flex',
                  alignItems: 'center',
                  p: 1,
                  '& .MuiAlert-message': { p: 0 },
                }}
                severity='error'
              >
                Close time must be greater than open time
              </Alert>
            )}
          </CustomCard>
        );
      })}
    </Box>
  );
};
export default OperatingHourCards;
