import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../../../components/shared/CustomAppBarBack';
import AreaAddForms from '../../../components/AreaAddForms';

const AddArea = ({ matches, permissions }) => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title="Add Area" />
      <Container maxWidth="lg" disableGutters>
        <AreaAddForms matches={matches} permissions={permissions} />
      </Container>
    </Box>
  );
};
export default AddArea;
