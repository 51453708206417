export const orderTypes = Object.freeze({
  dineIn: 1,
  takeAway: 2,
});

export const days = Object.freeze({
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
});

export const operatingHourStatus = Object.freeze({
  open: 'open',
  closed: 'closed',
  temporaryClosed: 'temporarily-closed',
});

export const invoiceStatus = Object.freeze({
  pending: 100,
  paid: 200,
  finished: 201,
  customer_cancelled: 300,
  admin_cancelled: 301,
  expired: 401,
  failed: 500,
});

export const viewAccess = Object.freeze({
  order: 'view-outlet-invoice',
  station: 'view-station',
  productCategory: 'view-product-category',
  product: 'view-product',
  paymentMethod: 'view-outlet-payment-method',
  banner: 'view-outlet-banner',
  orderType: 'view-outlet-order-type',
  operatingHour: 'view-outlet-operating-hour',
});
