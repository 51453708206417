import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DrawerHeader } from '../helpers/general';
import CustomInput from './shared/CustomInput';
import {
  getDetailArea,
  updateArea,
  uploadImageArea,
  uploadVideoRoom,
} from '../api/Area';
import { ReactComponent as Trash } from '../assets/icons/trash.svg';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomButton from './shared/CustomButton';

const AreaEditForms = ({ matches, permissions }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { idArea } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  //! name
  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);

  //! image
  const [image, setImage] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [isMaxSizeImage, setIsMaxSizeImage] = useState(false);
  const [isUploadImage, setIsUploadImage] = useState(false);
  const [isImagePriview, setIsImagePreview] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const [notifImage, setNotifImage] = useState();

  //! video
  const [video, setVideo] = useState(null);
  const [isMaxSizeVideo, setIsMaxSizeVideo] = useState(false);
  const [isUploadVideo, setIsUploadVideo] = useState(false);
  const [isVideoPriview, setIsVideoPreview] = useState(false);
  const [videoPreview, setVideoPreview] = useState('');
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);

  // ! get spesifik area
  const getSpesifikArea = async () => {
    setIsLoading(true);
    try {
      const { data: data } = await getDetailArea(idArea);
      setName(data.name);
      const images = data?.images.map(({ id, imageUrl, tableCount }) => ({
        id,
        image_url: imageUrl,
        tables: tableCount,
      }));
      setImage(images);

      data?.videoUrl !== null && setVideo(data?.videoUrl);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSpesifikArea();
  }, []);

  //! --------------------- handle --------------------------

  // ! handle image
  const handleImage = (e) => {
    const img = e.target.files[0];
    if (img.size > 1000000) {
      setIsMaxSizeImage(true);
      setNotifImage('Gambar tidak boleh lebih dari 1 MB');
    } else {
      setIsUploadImage(true);
      let datas = {
        image: img,
      };
      uploadImageArea(datas).then((res) => {
        setImage([...image, { image_url: res.data.data.image_url }]);
        setIsMaxSizeImage(false);
        setIsUploadImage(false);
      });
    }
  };

  // ! update url image
  const updateImageUrl = (e) => {
    const img = e.target.files[0];
    if (img.size > 1000000) {
      setIsMaxSizeImage(true);
      setNotifImage('Gambar tidak boleh lebih dari 1 MB');
    } else {
      setIsUploadImage(true);
      let datas = {
        image: img,
      };
      uploadImageArea(datas).then((res) => {
        const i = image.findIndex(
          (url) => url.image_url === selectedImage.image_url
        );

        const collect = image;

        if (selectedImage.id) {
          collect[i] = {
            id: selectedImage.id,
            image_url: res.data.data.image_url,
          };
        } else {
          collect[i] = {
            image_url: res.data.data.image_url,
          };
        }

        setImage(collect);

        setIsMaxSizeImage(false);
        setIsUploadImage(false);
      });
    }
  };

  // ! handle video
  const handleVideo = (e) => {
    const vd = e.target.files[0];
    if (vd.size > 1000000000) {
      setIsMaxSizeVideo(true);
    } else {
      setIsLoadingVideo(true);
      let datas = {
        video: vd,
      };
      uploadVideoRoom(datas).then((res) => {
        console.log(res.data.data.video_url);
        setVideo(res.data.data.video_url);
        setIsMaxSizeVideo(false);
        setIsLoadingVideo(false);
      });
    }
  };

  // ! validation submit
  useEffect(() => {
    if (name !== '' && image.length !== 0 && video !== null) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [image, video, name]);

  // ! button submit
  const handleSubmit = () => {
    let datas = {
      name: name,
      images: image,
      video_url: video,
    };
    const saveTable = async () => {
      setIsLoading(true);
      try {
        const data = await updateArea(idArea, datas);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    saveTable();
    navigate(-1);
  };
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2,
        p: 2,
      }}
    >
      <Box>
        <DrawerHeader />

        {/* main */}
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container columns={12} spacing={2}>
            {/* name */}
            <Grid item xs={12}>
              <CustomInput
                label="Area Name*"
                readOnly={!permissions.includes('add-outlet-area')}
                fullWidth
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                  setErrorName(false);
                }}
                sx={{
                  '& .MuiOutlinedInput-input': {
                    paddingInline: '14px',
                    fontSize: '12px',
                  },
                  mt: 1,
                }}
                error={errorName}
                helperText={errorName && 'required'}
              />
            </Grid>

            {/* image */}
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Box>
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                  Photo*
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 500, color: '#9E9D9D' }}
                >
                  Max photos: 4, file size: 1MB
                </Typography>
                {notifImage && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 500,
                        color: '#FF0000',
                      }}
                    >
                      {notifImage}
                    </Typography>

                    <CancelIcon
                      sx={{
                        fontSize: '12px',
                        cursor: 'pointer',
                      }}
                      onClick={() => setNotifImage()}
                    />
                  </Box>
                )}
              </Box>

              {/* add */}
              <Box
                sx={{
                  height: '110px',
                  width: '100%',
                  mt: '12px',
                  borderRadius: '8px',
                  bgcolor: '#F5F5F5',
                  borderStyle: 'dashed',
                  borderColor: '#9E9D9D',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexFlow: 'column',
                }}
                onClick={() => {
                  document.getElementById('input_img').click();
                }}
              >
                <input
                  accept="image/*"
                  id="input_img"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleImage}
                />
                <ImageOutlinedIcon
                  sx={{ color: '#9E9D9D', fontSize: '25px' }}
                />
                <Typography sx={{ color: '#9E9D9D', fontSize: '12px' }}>
                  Add Photo
                </Typography>
              </Box>

              <Box sx={{}}>
                {image.map((input, index) => (
                  <Box
                    sx={{
                      height: '60px',
                      background: '#FFFFFF',
                      boxShadow: '0px 0.5px 10px rgba(0, 0, 0, 0.1)',
                      mt: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px 10px',
                      gap: 2,
                      justifyContent: 'space-between',
                      borderRadius: '12px',
                    }}
                    onClick={() => {
                      document.getElementById('update_img').click();
                      setSelectedImage(input);
                    }}
                    key={index}
                  >
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <input
                        accept="image/*"
                        id="update_img"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={updateImageUrl}
                      />
                      <Box
                        component="img"
                        src={input?.image_url}
                        sx={{
                          height: '40px',
                          width: '40px',
                          aspectRatio: '1/1',
                          borderRadius: '8px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setIsImagePreview(!isImagePriview);
                          setImagePreview(input);
                        }}
                      />
                      <Box>
                        <Typography sx={{ color: '#000000', fontSize: '12px' }}>
                          area{index + 1}.png
                        </Typography>
                        <Typography sx={{ color: '#4FD240', fontSize: '10px' }}>
                          {input?.tables} table
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      {input?.tables === 0 ? (
                        <Trash
                          onClick={(e) => {
                            e.stopPropagation();
                            setImage(image.filter((item) => item !== input));
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>

            {/* video */}
            <Grid item xs={12} sx={{ mb: 4 }}>
              <Box>
                <Typography
                  sx={{ mt: '16px', fontSize: '14px', fontWeight: 600 }}
                >
                  Video*
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 500, color: '#9E9D9D' }}
                >
                  Max file size: 50MB
                </Typography>
              </Box>

              {/* add  */}
              <Box
                sx={{
                  height: '110px',
                  width: '100%',
                  mt: '12px',
                  borderRadius: '8px',
                  bgcolor: '#F5F5F5',
                  borderStyle: 'dashed',
                  borderColor: '#9E9D9D',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexFlow: 'column',
                }}
                onClick={() => {
                  document.getElementById('input_video').click();
                }}
              >
                <input
                  accept="video/*"
                  id="input_video"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleVideo}
                />

                {isLoadingVideo ? (
                  <CircularProgress />
                ) : (
                  <Box>
                    <ImageOutlinedIcon
                      sx={{ color: '#9E9D9D', fontSize: '25px' }}
                    />
                    <Typography sx={{ color: '#9E9D9D', fontSize: '12px' }}>
                      Add Video
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box sx={{}}>
                {video !== null && (
                  <Box
                    sx={{
                      height: '60px',
                      background: '#FFFFFF',
                      boxShadow: '0px 0.5px 10px rgba(0, 0, 0, 0.1)',
                      mt: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px 10px',
                      gap: 2,
                      justifyContent: 'space-between',
                      borderRadius: '12px',
                    }}
                    onClick={() => {
                      document.getElementById('input_video').click();
                    }}
                    // key={index}
                  >
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <video
                        style={{
                          maxHeight: '40px',
                          height: '40px',
                          maxWidth: '40px',
                          width: '40px',
                          borderRadius: '8px',
                          cursor: 'pointer',
                          backgroundColor: 'black',
                        }}
                      >
                        <source src={video} />
                      </video>
                      <Box>
                        <Typography sx={{ color: '#000000', fontSize: '12px' }}>
                          area1.mp4
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Trash
                        onClick={(event) => {
                          setVideo(null);
                          event.stopPropagation();
                        }}
                      />
                    </Box>
                    <input
                      accept="video/*"
                      id="input_video"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleVideo}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      {/* button submit */}
      <CustomButton
        onClick={handleSubmit}
        disabled={!isDisabled}
        type="submit"
        fullWidth
        variant="contained"
      >
        Save
      </CustomButton>
    </Box>
  );
};

export default AreaEditForms;
