import { createSlice } from '@reduxjs/toolkit';

export const AddSubCategoryState = createSlice({
  name: 'addSubCategory',
  initialState: {},
  reducers: {},
});

export const {} = AddSubCategoryState.actions;




//! fetch 

export default AddSubCategoryState.reducer;
