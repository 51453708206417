import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postProduct, postProductImage } from '../api/product';
import CustomButton from './shared/CustomButton';
import CustomInput from './shared/CustomInput';
import {
  DrawerHeader,
  maxFontSize,
  responsiveFontSize,
} from '../helpers/general';
import CustomSelect from './shared/CustomSelect';
import { imagePlaceholder } from '../assets/images';
import CustomAutocomplete from './shared/CustomAutocomplete';
import {
  getCategoryByStatus,
  getProductCategories,
  getSubCategory,
  getSubCategoryByStatus,
} from '../api/productCategory';
import { getOrderTypes } from '../api/orderType';
import CustomBottomDrawer from './shared/CustomBottomDrawer';
import CustomCard from './shared/CustomCard';

const ProductAddForms = ({ matches }) => {
  const navigate = useNavigate();

  const inputFile = document.getElementById('input_image');

  const handleClickImage = async () => {
    inputFile.click();
  };

  const handleChangeImage = async (event) => {
    if (event.target.files[0]) {
      const formData = new FormData();
      const file = event.target.files[0];

      formData.append('image', file, file.name);

      const {
        data: { imageUrl },
      } = await postProductImage(formData);
      setImage(imageUrl);
    }
  };

  const [image, setImage] = useState(null);
  const [errorImage, setErrorImage] = useState(false);

  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);

  const [desc, setDesc] = useState('');

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [category, setCategory] = useState(null);
  const [errorCategory, setErrorCategory] = useState(false);

  //! sub category
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategory, setSubCategory] = useState(null);
  const [errorSubCategory, setErrorSubCategory] = useState(false);
  // const [inputCategory, setInputCategory] = useState('');
  // const [savedInputCategory, setSavedInputCategory] = useState('');

  const fetchCategories = async () => {
    const { data } = await getCategoryByStatus();

    setCategoryOptions(data.map(({ id, name }) => ({ id, label: name })));
  };

  //! fetch sub category
  const fetchSubCategory = async (id) => {
    let params = {
      product_category_id: id,
    };
    try {
      const { data } = await getSubCategoryByStatus(params);

      setSubCategoryOptions(
        data.map((item) => ({ id: item.id, label: item.name }))
      );
    } catch (err) {
      console.error(err);
    }
  };

  const [orderTypes, setOrderTypes] = useState([]);
  const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
  const [errorSelectedOrderTypes, setErrorSelectedOrderTypes] = useState(false);

  const [isOpenOrderTypes, setIsOpenOrderTypes] = useState(false);

  const fetchOrderTypes = async () => {
    const { data: orderTypes } = await getOrderTypes();

    setOrderTypes(orderTypes);
  };

  const handleChangeOrderType = async (orderType) => {
    setErrorSelectedOrderTypes(false);

    let tempOrderTypes = [...selectedOrderTypes];
    let tempOrderType = tempOrderTypes.find(
      (tempOrderType) => tempOrderType.id === orderType.orderTypeId
    );

    if (tempOrderType) {
      tempOrderTypes.splice(tempOrderTypes.indexOf(tempOrderType), 1);
    } else {
      tempOrderTypes.push({ id: orderType.orderTypeId, name: orderType.name });
    }

    tempOrderTypes.sort((a, b) => a?.id - b?.id);

    setSelectedOrderTypes(tempOrderTypes);
  };

  useEffect(() => {
    fetchCategories();
    fetchOrderTypes();
  }, []);

  const [price, setPrice] = useState('');
  const [errorPrice, setErrorPrice] = useState(false);

  const statusOptions = [
    { name: 'Active', value: 1 },
    { name: 'Inactive', value: 0 },
  ];
  const [status, setStatus] = useState(1);
  // const [errorStatus, setErrorStatus] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      name &&
      image &&
      category &&
      selectedOrderTypes.length &&
      price !== ''
    ) {
      // setIsLoading(true);
      const sortNumber = 0;

      const productCategoryData = {
        name,
        image_url: image,
        description: desc ? desc : null,
        product_category_id: category?.id,
        product_subcategory_id: subCategory?.id,
        order_types: selectedOrderTypes.map((orderType) => orderType.id),
        default_price: price,
        status: +status,
        sort_number: sortNumber,
      };

      await postProduct(productCategoryData)
        .then(() => {
          navigate('/product', { replace: true });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      name ? setErrorName(false) : setErrorName(true);
      image ? setErrorImage(false) : setErrorImage(true);
      category ? setErrorCategory(false) : setErrorCategory(true);
      selectedOrderTypes.length
        ? setErrorSelectedOrderTypes(false)
        : setErrorSelectedOrderTypes(true);
      price !== '' ? setErrorPrice(false) : setErrorPrice(true);
      // status ? setErrorStatus(false) : setErrorStatus(true);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2,
        p: 2,
      }}
    >
      <Box>
        <DrawerHeader />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box
              component="img"
              src={image || imagePlaceholder}
              sx={{
                width: 1,
                borderRadius: '12px',
                cursor: 'pointer',
                aspectRatio: '1 / 1',
              }}
              onClick={handleClickImage}
            />
            <input
              accept="image/*"
              type="file"
              id="input_image"
              style={{ display: 'none' }}
              onChange={handleChangeImage}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                fontWeight: 500,
                color: 'info.main',
                fontSize: matches
                  ? responsiveFontSize(14) + 'vw'
                  : maxFontSize(14) + 'px',
              }}
            >
              NB: Format JPG, JPEG, PNG (rasio 1:1, min 300 x 300px, max 1MB)
            </Typography>
            {errorImage && (
              <Typography
                sx={{
                  mt: 1,
                  fontSize: matches
                    ? responsiveFontSize(14) + 'vw'
                    : maxFontSize(14) + 'px',
                  color: 'error.main',
                }}
              >
                * required
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} id="name">
            <CustomInput
              label="Name*"
              fullWidth
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                setErrorName(false);
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
              error={errorName}
              helperText={errorName && 'required'}
            />
          </Grid>
          <Grid item xs={12} id="desc">
            <CustomInput
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={desc}
              onChange={(event) => {
                setDesc(event.target.value);
              }}
              inputProps={{ style: { padding: 0 } }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
            />
          </Grid>
          <Grid item xs={6} id="category">
            <CustomAutocomplete
              fullWidth
              label="Product Category*"
              placeholder="Product Category"
              error={errorCategory}
              helperText={errorCategory && 'required'}
              value={category}
              onChange={(event, newValue) => {
                setCategory(newValue);
                setErrorCategory(false);
                fetchSubCategory(newValue?.id);
              }}
              // inputValue={inputCategory}
              // onInputChange={(event, newInputValue) => {
              //   setInputCategory(newInputValue);
              // }}
              options={categoryOptions}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.id}>
                  {option.label}
                </MenuItem>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                '& .MuiOutlinedInput-input': {
                  fontSize: '0.875rem',
                },
                '& .MuiButtonBase-root': {
                  p: 0,
                  pt: '2px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6} id="sub category">
            <CustomAutocomplete
              fullWidth
              label="Sub Category"
              placeholder="Sub Category"
              error={errorSubCategory}
              // helperText={errorSubCategory && 'required'}
              value={subCategory}
              onChange={(event, newValue) => {
                setSubCategory(newValue);
                setErrorSubCategory(false);
              }}
              options={subCategoryOptions}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.id}>
                  {option.label}
                </MenuItem>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                '& .MuiOutlinedInput-input': {
                  fontSize: '0.875rem',
                },
                '& .MuiButtonBase-root': {
                  p: 0,
                  pt: '2px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} id="order-type">
            <CustomInput
              readOnly
              fullWidth
              label="Order Type*"
              placeholder="Order Type"
              value={selectedOrderTypes
                .map((orderType) => orderType.name)
                .join(', ')}
              error={errorSelectedOrderTypes}
              helperText={errorSelectedOrderTypes && 'required'}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px 8px',
                  fontSize: '0.875rem',
                  textTransform: 'capitalize',
                },
                '& .MuiSvgIcon-root': { mr: -1 },
              }}
              onClick={() => {
                setIsOpenOrderTypes(!isOpenOrderTypes);
              }}
              endAdornment={<ArrowDropDownIcon />}
            />
            <CustomBottomDrawer
              isOpen={isOpenOrderTypes}
              setIsOpen={setIsOpenOrderTypes}
            >
              <DialogTitle>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>Order Type</Typography>
                  <CloseIcon
                    color="info"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setIsOpenOrderTypes(!isOpenOrderTypes)}
                  />
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {orderTypes.length > 0 &&
                    orderTypes.map((orderType, index) => (
                      <CustomCard
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '12px',
                            textTransform: 'capitalize',
                          }}
                        >
                          {orderType.name}
                        </Typography>
                        <Switch
                          size="small"
                          sx={{ mr: -0.5 }}
                          onChange={() => handleChangeOrderType(orderType)}
                          checked={Boolean(
                            selectedOrderTypes.find(
                              (selectedOrderType) =>
                                selectedOrderType.id === orderType.orderTypeId
                            )
                          )}
                        />
                      </CustomCard>
                    ))}
                </Box>
              </DialogContent>
            </CustomBottomDrawer>
          </Grid>
          <Grid item xs={6} id="price">
            <CustomInput
              type="number"
              label="Price*"
              placeholder="25000"
              startAdornment={'Rp'}
              value={price}
              onChange={(event) => {
                setPrice(event.target.value);
                setErrorPrice(false);
              }}
              error={errorPrice}
              helperText={errorPrice && 'required'}
            />
          </Grid>
          <Grid item xs={6} id="status">
            <CustomSelect
              fullWidth
              label="Status*"
              value={status}
              // error={errorStatus}
              // helperText={errorStatus && 'required'}
              onChange={(event) => setStatus(event.target.value)}
              options={statusOptions}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <CustomButton
        onClick={handleSubmit}
        disabled={isLoading}
        type="submit"
        fullWidth
        variant="contained"
      >
        Submit
      </CustomButton>
    </Box>
  );
};
export default ProductAddForms;
