import { Fab } from '@mui/material';

const CustomFab = ({ color, icon, ...props }) => {
  return (
    <Fab {...props} color={color}>
      {icon}
    </Fab>
  );
};

export default CustomFab;
