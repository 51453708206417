import {
  Alert,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import CustomButton from '../components/shared/CustomButton';
import CustomInput from '../components/shared/CustomInput';
import useAuth from '../hooks/useAuth';
import BackgroundImageLogin from '../assets/images/background-image-login.png';
import LogoEnak from '../assets/images/logo-enak.png';
import { viewAccess } from '../helpers/enum';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logIn, permissions, accessToken } = useAuth();

  const adminPermissions = Object.values(viewAccess);
  const routes = [
    'order',
    'station',
    'product-category',
    'product',
    'payment-method',
    'banner',
    'order-type',
    'operating-hour',
  ];

  const handlePermissions = () => {
    for (let i = 0; i < adminPermissions.length; i++) {
      if (permissions.includes(adminPermissions[i])) {
        navigate(routes[i], { replace: true });
        break;
      }
    }
  };

  useEffect(() => {
    if (accessToken && permissions.length) handlePermissions();
  }, [accessToken, permissions]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isShownPassword, setIsShownPassword] = useState(false);

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email !== '' && password !== '') {
      try {
        setIsLoading(true);

        await logIn({ email, password });
      } catch (error) {
        setError(true);

        const status = error?.response?.status;

        if (status === 401) {
          setErrorMessage('Wrong email or password');
        } else if (status === 422) {
          setErrorMessage('Password must be at least 6 characters');
        } else {
          setErrorMessage('Error');
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      email !== '' ? setErrorEmail(false) : setErrorEmail(true);
      password !== '' ? setErrorPassword(false) : setErrorPassword(true);
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${BackgroundImageLogin})`,
        boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0, 0.56)',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        height: 1,
        backgroundSize: 'cover',
      }}
    >
      <Container maxWidth="lg" disableGutters>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            flex: 1,
            minHeight: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              bgcolor: '#FFFFFF',
              p: 2.5,
              borderRadius: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxWidth: '444px',
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box
                component="img"
                src={LogoEnak}
                sx={{
                  width: 0.25,
                  aspectRatio: '1/1',
                }}
              />
            </Box>
            <Typography
              sx={{ fontSize: '28px', fontWeight: 700, textAlign: 'center' }}
            >
              Admin Enak.id
            </Typography>

            <CustomInput
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '16px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  boxShadow:
                    errorEmail && `0 0 4px 0 ${theme.palette.error.main}`,
                },
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    boxShadow:
                      errorEmail && `0 0 4px 0 ${theme.palette.error.main}`,
                  },
                },
              }}
              fullWidth
              autoComplete="email"
              type="email"
              label="Email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setErrorEmail(false);
              }}
              error={errorEmail}
              helperText={errorEmail && 'required'}
            />

            <CustomInput
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '16px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  boxShadow:
                    errorPassword && `0 0 4px 0 ${theme.palette.error.main}`,
                },
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    boxShadow:
                      errorPassword && `0 0 4px 0 ${theme.palette.error.main}`,
                  },
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '16px',
                },
              }}
              fullWidth
              autoComplete="current-password"
              type={isShownPassword ? 'text' : 'password'}
              endAdornment={
                <IconButton
                  onClick={() => setIsShownPassword(!isShownPassword)}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {isShownPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              }
              label="Password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
                setErrorPassword(false);
              }}
              error={errorPassword}
              helperText={errorPassword && 'required'}
            />

            <Alert
              severity="error"
              onClose={() => {
                setError(false);
              }}
              sx={{ display: !error && 'none' }}
            >
              {errorMessage}
            </Alert>

            <CustomButton
              disabled={isLoading}
              fullWidth
              type="submit"
              variant="contained"
              endIcon={
                <Box
                  sx={{ transform: 'scaleX(-1)', lineHeight: 0.75, mt: -0.25 }}
                >
                  {isLoading && (
                    <CircularProgress size={12} sx={{ color: '#fff' }} />
                  )}
                </Box>
              }
            >
              Login
            </CustomButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default Login;
