import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getPaymentMethods } from '../api/paymentMethod';
import PaymentMethodCards from '../components/PaymentMethodCards';
import CustomAppBar from '../components/shared/CustomAppBar';

const PaymentMethod = ({ permissions }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);

  const fetchPaymentMethods = async () => {
    try {
      setIsLoading(true);
      const { data: paymentMethods } = await getPaymentMethods();
      setPaymentMethods(
        paymentMethods.map((paymentMethod) => ({
          isExpanded: false,
          ...paymentMethod,
        }))
      );
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  return (
    <Box>
      <CustomAppBar title='Payment Method' />
      <Container maxWidth='lg' disableGutters>
        <Box sx={{ p: 2 }}>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '500px',
                transform: 'scaleX(-1)',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {!isLoading && paymentMethods.length === 0 && (
            <Typography sx={{ fontSize: '0.875rem' }}>
              No payment method available
            </Typography>
          )}

          {!isLoading && paymentMethods.length > 0 && (
            <PaymentMethodCards
              paymentMethodGroups={paymentMethods}
              setPaymentMethodGroups={setPaymentMethods}
              permissions={permissions}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};
export default PaymentMethod;
