import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from './shared/CustomButton';
import CustomInput from './shared/CustomInput';
import { DrawerHeader } from '../helpers/general';
import { bannerAddPlaceholder } from '../assets/images';
import { postBanner, postBannerImage } from '../api/banner';

const BannerAddForms = () => {
  const navigate = useNavigate();

  const [inputFile, setInputFile] = useState(
    document.getElementById('input_image')
  );

  useEffect(() => {
    setInputFile(document.getElementById('input_image'));
  }, []);

  const handleClickImage = () => {
    inputFile.click();
  };

  const handleChangeImage = async (event) => {
    if (event.target.files[0]) {
      const formData = new FormData();
      const file = event.target.files[0];

      formData.append('image', file, file.name);

      const {
        data: { imageUrl },
      } = await postBannerImage(formData);
      setImage(imageUrl);
    }
  };

  const [image, setImage] = useState(null);
  const [errorImage, setErrorImage] = useState(false);

  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (name && image) {
      setIsLoading(true);

      const bannerData = {
        name,
        image_url: image,
      };

      await postBanner(bannerData)
        .then(() => {
          navigate('/banner', { replace: true });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      name ? setErrorName(false) : setErrorName(true);
      image ? setErrorImage(false) : setErrorImage(true);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2,
        p: 2,
      }}
    >
      <Box>
        <DrawerHeader />
        <Grid container spacing={2}>
          <Grid item xs={12} id="image">
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                Banner*{' '}
                {errorImage && (
                  <FormHelperText component="span" sx={{ color: 'error.main' }}>
                    required
                  </FormHelperText>
                )}
              </Typography>
            </Box>

            <Typography
              sx={{ fontWeight: 500, fontSize: '12px', color: 'info.main' }}
            >
              Format file: JPG, JPEG, PNG, Ratio: 7:4, Max. file size: 1 MB
            </Typography>

            <Box
              component="img"
              src={image || bannerAddPlaceholder}
              sx={{
                width: 1,
                aspectRatio: '7 / 4',
                borderRadius: '8px',
                mt: 1,
                cursor: 'pointer',
              }}
              onClick={handleClickImage}
            />
            <input
              accept="image/*"
              type="file"
              id="input_image"
              style={{ display: 'none' }}
              onChange={handleChangeImage}
            />
          </Grid>
          <Grid item xs={12} id="name">
            <CustomInput
              label="Name*"
              fullWidth
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                setErrorName(false);
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
              error={errorName}
              helperText={errorName && 'required'}
            />
          </Grid>
        </Grid>
      </Box>
      <CustomButton
        onClick={handleSubmit}
        disabled={isLoading}
        type="submit"
        fullWidth
        variant="contained"
      >
        Submit
      </CustomButton>
    </Box>
  );
};
export default BannerAddForms;
