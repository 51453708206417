import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { DrawerHeader } from '../../helpers/general';
import CustomDrawer from '../../components/shared/CustomDrawer';
import { Menu as MenuIcon, Add as AddIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CustomCard from '../../components/shared/CustomCard';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomFab from '../../components/shared/CustomFab';
import {
  getAllDataSubCategory,
  getCategoryName,
  getIdCategory,
  getListCategory,
} from '../../store/category/subCategory/subCategory';
import {
  getSpesifikProductCategoryById,
  updateStatusSubCategory,
} from '../../api/productCategory';

const SubCategoryMenu = () => {
  const { idCategory } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const categoryName = useSelector(getCategoryName);
  const [categoryNameFromAdd, setCategoryNameFromAdd] = useState('');
  const datas = useSelector(getListCategory);

  // !get Spesisik category

  const categoryname = async () => {
    try {
      const data = await getSpesifikProductCategoryById(idCategory);
      setCategoryNameFromAdd(data.data?.name);
    } catch (err) {
      console.error(err);
    }
  };

  const setStatus = async (id) => {
    try {
      const data = await updateStatusSubCategory(id);
    } catch (err) {
      console.error(err);
    } finally {
      let params = {
        product_category_id: idCategory,
      };
      dispatch(getAllDataSubCategory(params));
    }
  };

  useEffect(() => {
    categoryname();
    let params = {
      product_category_id: idCategory,
    };
    dispatch(getAllDataSubCategory(params));
  }, [idCategory]);
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ color: '#000' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexGrow: 1,
              ml: 1,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              {/* {nameArea} */} Sub Category
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <DrawerHeader />
      <CustomDrawer isOpen={isOpen} setIsOpen={setIsOpen} />

      {/* main */}

      <Container maxWidth="lg" disableGutters sx={{ paddingBottom: '50px' }}>
        <Box sx={{ p: 2 }}>
          {/* back button  */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 2,
            }}
            onClick={() => navigate('product-category')}
          >
            <IconButton
              edge="start"
              sx={{
                background: '#4FD240',
                width: '16px',
                padding: '10px',
                height: '16px',
                mr: '16px',
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosNewIcon sx={{ width: '15px', color: '#FFFFFF' }} />
            </IconButton>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#000' }}
            >
              {categoryNameFromAdd}
            </Typography>
          </Box>
          <Grid container columns={12} spacing={3}>
            {datas.length !== 0 ? (
              datas.map((item, index) => (
                <Grid item xs={12}>
                  <CustomCard>
                    <Box
                      component={Link}
                      to={`/product-category/editSubCategory/${item?.id}`}
                      sx={{
                        display: 'flex',
                        gap: 2,
                        textDecoration: 'none',
                        color: '#000000',
                      }}
                    >
                      {/* image */}
                      <Box>
                        <Box
                          component="img"
                          src={item?.imageUrl}
                          sx={{
                            width: '64px',
                            height: '64px',
                            borderRadius: '12px',
                          }}
                        />
                      </Box>

                      {/* name */}
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{ fontSize: '16px', fontWeight: 600 }}
                          >
                            {item?.name}
                          </Typography>
                        </Box>
                        <Box>
                          <Switch
                            checked={item?.status}
                            onChange={() => {
                              setStatus(item?.id);
                            }}
                            onClick={(event) => event.stopPropagation()}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </CustomCard>
                </Grid>
              ))
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '50vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography>Nodata Found</Typography>
              </Box>
            )}
          </Grid>

          {/* {permissions.includes('add-product-category') && ( */}
          <CustomFab
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            color="primary"
            icon={<AddIcon />}
            component={Link}
            to={`/product-category/addSubCategory`}
          />
          {/* )} */}
        </Box>
      </Container>
    </Box>
  );
};
export default SubCategoryMenu;
