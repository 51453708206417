import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getProductcategoryData,
  postProductCategory,
  postProductCategoryImage,
} from '../api/productCategory';
import CustomButton from './shared/CustomButton';
import CustomInput from './shared/CustomInput';
import {
  DrawerHeader,
  maxFontSize,
  responsiveFontSize,
} from '../helpers/general';
import CustomSelect from './shared/CustomSelect';
import { imagePlaceholder } from '../assets/images';
import { getStations } from '../api/station';
import CustomAutocomplete from './shared/CustomAutocomplete';

const ProductCategoryAddForms = ({ matches }) => {
  const navigate = useNavigate();

  const inputFile = document.getElementById('input_image');

  const handleClickImage = async () => {
    inputFile.click();
  };

  const handleChangeImage = async (event) => {
    if (event.target.files[0]) {
      const formData = new FormData();
      const file = event.target.files[0];

      formData.append('image', file, file.name);

      const {
        data: { imageUrl },
      } = await postProductCategoryImage(formData);
      setImage(imageUrl);
    }
  };

  const [image, setImage] = useState(null);
  const [errorImage, setErrorImage] = useState(false);

  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);

  const [desc, setDesc] = useState('');

  const [stationOptions, setStationOptions] = useState([]);
  const [station, setStation] = useState(null);
  const [errorStation, setErrorStation] = useState(false);
  // const [inputStation, setInputStation] = useState('');
  // const [savedInputStation, setSavedInputStation] = useState('');

  const fetchStations = async () => {
    const {
      data: { stations },
    } = await getStations({ pageSize: 100 });

    setStationOptions(
      stations
        .filter((list) => list.name !== 'Reservation - Kanpai')
        .map(({ id, name }) => ({ id, label: name }))
    );
  };

  useEffect(() => {
    fetchStations();
  }, []);

  const statusOptions = [
    { name: 'Active', value: 1 },
    { name: 'Inactive', value: 0 },
  ];
  const [status, setStatus] = useState(1);
  // const [errorStatus, setErrorStatus] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (name && image && station) {
      setIsLoading(true);
      const sortNumber = 0;

      const productCategoryData = {
        name,
        image_url: image,
        description: desc ? desc : null,
        station_id: station?.id,
        status: +status,
        sort_number: sortNumber,
      };

      try {
        await postProductCategory(productCategoryData);
      } catch (err) {
        console.error(err);
      } finally {
        const {
          data: { productCategories },
        } = await getProductcategoryData({ page_size: 200 });
        const idAll = productCategories.map((list) => {
          return list.id;
        });
        const lastId = Math.max(...idAll);
        navigate(`/product-category/detail/${lastId}`);
      }
    } else {
      name ? setErrorName(false) : setErrorName(true);
      image ? setErrorImage(false) : setErrorImage(true);
      station ? setErrorStation(false) : setErrorStation(true);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2,
        p: 2,
      }}
    >
      <Box>
        <DrawerHeader />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box
                component="img"
                src={image || imagePlaceholder}
                sx={{
                  height: '125px',
                  width: '125px',
                  borderRadius: '12px',
                  cursor: 'pointer',
                  aspectRatio: '1 / 1',
                }}
                onClick={handleClickImage}
              />
              <input
                accept="image/*"
                type="file"
                id="input_image"
                style={{ display: 'none' }}
                onChange={handleChangeImage}
              />
              <Typography
                sx={{
                  fontWeight: 500,
                  color: 'info.main',
                  fontSize: matches
                    ? responsiveFontSize(14) + 'vw'
                    : maxFontSize(14) + 'px',
                }}
              >
                NB: Format JPG, JPEG, PNG (rasio 1:1, min 300 x 300px, max 1MB)
              </Typography>
              {errorImage && (
                <Typography
                  sx={{
                    mt: 1,
                    fontSize: matches
                      ? responsiveFontSize(14) + 'vw'
                      : maxFontSize(14) + 'px',
                    color: 'error.main',
                  }}
                >
                  * required
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} id="name">
            <CustomInput
              label="Name*"
              fullWidth
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                setErrorName(false);
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
              error={errorName}
              helperText={errorName && 'required'}
            />
          </Grid>
          <Grid item xs={12} id="desc">
            <CustomInput
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={desc}
              onChange={(event) => {
                setDesc(event.target.value);
              }}
              inputProps={{ style: { padding: 0 } }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} id="station">
            <CustomAutocomplete
              fullWidth
              label="Station*"
              placeholder="Station"
              value={station}
              error={errorStation}
              helperText={errorStation && 'required'}
              onChange={(event, newValue) => {
                setStation(newValue);
              }}
              // inputValue={inputStation}
              // onInputChange={(event, newInputValue) => {
              //   setInputStation(newInputValue);
              // }}
              options={stationOptions}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.id}>
                  {option.label}
                </MenuItem>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                '& .MuiOutlinedInput-root': {
                  fontSize: '0.875rem',
                },
                '& .MuiButtonBase-root': {
                  p: 0,
                  pt: '2px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} id="status">
            <CustomSelect
              fullWidth
              label="Status*"
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              options={statusOptions}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <CustomButton
        onClick={handleSubmit}
        disabled={isLoading}
        type="submit"
        fullWidth
        variant="contained"
      >
        Submit
      </CustomButton>
    </Box>
  );
};
export default ProductCategoryAddForms;
