import { useEffect, useRef } from 'react';

import { Box, Container } from '@mui/material';
import lottie from 'lottie-web';
import animationData from '../assets/animations/404.json';
import CustomButton from '../components/shared/CustomButton';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const element = useRef(null);

  useEffect(() => {
    const instance = lottie.loadAnimation({
      animationData,
      container: element.current,
    });

    return () => instance.destroy();
  }, []);

  return (
    <Container maxWidth="md" disableGutters>
      <Box ref={element} sx={{ width: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CustomButton variant="text" onClick={() => navigate('/')}>
          Go Home
        </CustomButton>
      </Box>
    </Container>
  );
};

export default NotFound;
