import { configureStore } from '@reduxjs/toolkit';
import detailArea from './area/detailArea';
import addTable from './table/addTable';
import subCategory from './category/subCategory/subCategory';
import addSubCategory from './category/subCategory/add/addSubCategory';
import getStation from './Station/station';

export default configureStore({
  reducer: {
    detailArea: detailArea,
    addTable: addTable,
    subCategory: subCategory,
    addSubCategory: addSubCategory,
    getStation: getStation,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
