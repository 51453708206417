import { createSlice } from '@reduxjs/toolkit';
import { getStations } from '../../api/station';

export const getStationState = createSlice({
  name: 'getStation',
  initialState: {
    station: null,
  },
  reducers: {
    loadStation: (state, action) => {
      state.station = action.payload;
    },
  },
});

export const { loadStation } = getStationState.actions;

export const getStationList = (state) => state.getStation.station;

//! -----------------------API ------------------------
export const fetchStation = () => (dispatch) => {
  const fetchData = async () => {
    try {
      const {
        data: { stations },
      } = await getStations();
      dispatch(
        loadStation(
          stations
            .filter((list) => list.name !== 'Reservation - Kanpai')
            .map(({ id, name }) => ({ id, label: name }))
        )
      );
    } catch (err) {
      console.error(err);
    }
  };
  fetchData();
};

export default getStationState.reducer;
