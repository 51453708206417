import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

const CustomSelect = ({ fullWidth, error, helperText, options, ...props }) => {
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <Select {...props}>
        {options.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
export default CustomSelect;
