import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../components/shared/CustomAppBarBack';
import BannerAddForms from '../components/BannerAddForms';

const BannerAdd = () => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title="Upload Banner" />
      <Container maxWidth="lg" disableGutters>
        <BannerAddForms />
      </Container>
    </Box>
  );
};
export default BannerAdd;
