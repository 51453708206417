import { Box, Container } from '@mui/material'
import React from 'react'
import AreaEditForms from '../../../components/AreaEditForms'
import CustomAppBarBack from '../../../components/shared/CustomAppBarBack'

const EditAreaMenu = ({ matches, permissions }) => {
    return (
        <Box sx={{ minHeight: '100vh', width: '100vw' }}>
            <CustomAppBarBack title="Edit Area" />
            <Container maxWidth="lg" disableGutters>
                <AreaEditForms matches={matches} permissions={permissions} />
            </Container>
        </Box>
    )
}

export default EditAreaMenu