import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../components/shared/CustomAppBarBack';
import ProductAddForms from '../components/ProductAddForms';

const ProductAdd = ({ matches }) => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title="Add Product" />
      <Container maxWidth="lg" disableGutters>
        <ProductAddForms matches={matches} />
      </Container>
    </Box>
  );
};
export default ProductAdd;
