import {
  Box,
  CircularProgress,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Search as SearchIcon,
  Add as AddIcon,
  Close as CloseIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import CustomAppBarFilter from '../components/shared/CustomAppBarFilter';
import CustomInput from '../components/shared/CustomInput';
import CustomFab from '../components/shared/CustomFab';
import ProductCards from '../components/ProductCards';
import CustomAutocomplete from '../components/shared/CustomAutocomplete';
import { getProductCategories } from '../api/productCategory';
import CustomBottomDrawer from '../components/shared/CustomBottomDrawer';
import CustomButton from '../components/shared/CustomButton';
import { getProducts } from '../api/product';

const Product = ({ matches, permissions }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);

  const [keyword, setKeyword] = useState(searchParams.get('keyword') || '');
  const [savedKeyword, setSavedKeyword] = useState(
    searchParams.get('keyword') || ''
  );
  const debounceKeyword = useRef(
    debounce((nextValue) => {
      setSavedKeyword(nextValue);
    }, 1000)
  ).current;

  const handleChangeKeyword = (event) => {
    const { value } = event.target;
    value !== ''
      ? updatedSearchParams.set('keyword', value)
      : updatedSearchParams.delete('keyword');
    setSearchParams(updatedSearchParams, { replace: true });
    setKeyword(value);
    debounceKeyword(value);
  };

  const handleClearKeyword = () => {
    updatedSearchParams.delete('keyword');
    setSearchParams(updatedSearchParams, { replace: true });
    setKeyword('');
    debounceKeyword('');
  };

  const [category, setCategory] = useState(null);
  const [savedCategory, setSavedCategory] = useState(null);
  const [categories, setCategories] = useState([]);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const fetchProductCategories = async () => {
    const {
      data: { productCategories },
    } = await getProductCategories({ pageSize: 100 });

    setCategories(
      productCategories.map((category) => ({
        id: category.id,
        label: category.name,
      }))
    );
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const fetchProducts = async ({ keyword, productCategoryId }) => {
    try {
      setIsLoading(true);
      const {
        data: { products, nextPageUrl },
      } = await getProducts({ keyword, productCategoryId });
      setProducts(products);

      if (nextPageUrl) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts({
      keyword: savedKeyword,
      productCategoryId: savedCategory?.id,
    });
  }, [savedKeyword, savedCategory]);

  const SearchField = (
    <CustomInput
      placeholder='Product'
      fullWidth
      value={keyword}
      onChange={handleChangeKeyword}
      startAdornment={<SearchIcon />}
      endAdornment={
        keyword !== '' && (
          <CloseIcon onClick={handleClearKeyword} sx={{ cursor: 'pointer' }} />
        )
      }
      sx={{ '& .MuiOutlinedInput-input': { paddingBlock: '8px' } }}
    />
  );

  const CategoryFilter = (
    <CustomInput
      id='category-filter'
      readOnly
      // fullWidth
      placeholder='Category'
      value={savedCategory?.label || ''}
      endAdornment={<ArrowDropDownIcon />}
      sx={{
        '& .MuiOutlinedInput-input': { paddingBlock: '8px' },
      }}
      onClick={() => setIsOpenFilter(!isOpenFilter)}
    />
  );

  const CategoryAutocomplete = (
    <CustomAutocomplete
      fullWidth
      placeholder='Category'
      options={categories}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.id}>
          {option.label}
        </MenuItem>
      )}
      value={savedCategory || category}
      onChange={(event, newValue) => {
        setCategory(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );

  useEffect(() => {
    const categoryFilterElement = document.getElementById('category-filter');
    categoryFilterElement.blur();
    setCategory(null);
  }, [isOpenFilter]);

  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarFilter
        title='Station'
        filters={[SearchField, CategoryFilter]}
        filtersSize={[1, 1]}
      /> 
      <CustomBottomDrawer isOpen={isOpenFilter} setIsOpen={setIsOpenFilter}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>Select Category</Typography>
            <CloseIcon
              color='info'
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsOpenFilter(!isOpenFilter)}
            />
          </Box>
        </DialogTitle>
        <DialogContent>{CategoryAutocomplete}</DialogContent>
        <DialogActions>
          <CustomButton
            variant='contained'
            fullWidth
            onClick={() => {
              setSavedCategory(category);
              setIsOpenFilter(!isOpenFilter);
            }}
          >
            Submit
          </CustomButton>
        </DialogActions>
      </CustomBottomDrawer>
      <Container maxWidth='lg' disableGutters>
        <Box sx={{ p: 2 }}>
          <Box>
            {isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '500px',
                  transform: 'scaleX(-1)',
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {!isLoading && products.length === 0 && (
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                No product found
              </Typography>
            )}

            {!isLoading && products.length > 0 && (
              <ProductCards
                products={products}
                setProducts={setProducts}
                hasMore={hasMore}
                setHasMore={setHasMore}
                keyword={keyword}
                matches={matches}
                permissions={permissions}
              />
            )}
          </Box>

          {permissions.includes('add-product') && (
            <CustomFab
              sx={{ position: 'fixed', bottom: 16, right: 16 }}
              color='primary'
              icon={<AddIcon />}
              onClick={() => navigate('add')}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};
export default Product;
