import { useDispatch, useSelector } from 'react-redux';
import { getCellChoose } from '../store/table/addTable';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  MobileStepper,
  Typography,
} from '@mui/material';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

import CustomButton from './shared/CustomButton';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DrawerHeader } from '../helpers/general';
import {
  changeIdArea,
  getColumn,
  getIndexGrid,
  getRow,
  getidArea,
} from '../store/area/detailArea';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CustomInput from './shared/CustomInput';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { useTheme } from '@emotion/react';
import {
  addNewTable,
  uploadImage,
  uploadTable,
  uploadVideo,
} from '../api/table';
import { useNavigate, useParams } from 'react-router-dom';
import CustomCard from './shared/CustomCard';
import { getDetailArea } from '../api/Area';
import Carousel from 'react-material-ui-carousel';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const TableAddForms = ({ matches, permissions }) => {
  const { idArea } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(false);
  const [clicked, setCliked] = useState(true);

  const [listImageArea, setListImageArea] = useState();
  const [outletDimensionRoomImageId, setOutletDimensionRoomImageId] =
    useState(null);
  const [position, setPosition] = useState({});
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);

  //! name
  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);

  //! capacity
  const [capacity, setCapacity] = useState('');
  const [errorCapacity, setErrorCapacity] = useState(false);

  // ! get spesifik area
  const fetchSpesifikArea = async () => {
    setIsLoading(true);
    try {
      const data = await getDetailArea(idArea);
      setListImageArea(data.data.images);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchSpesifikArea();
  }, []);

  // ! ---------------- get width and height element ----
  const myRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const test = () => {
    if (myRef.current) {
      setWidth(myRef.current.clientWidth);
      setHeight(myRef.current.clientHeight);
    }
  };

  // function updateWidth() {
  //   console.log(myRef.current);
  //   if (myRef.current) {
  //     setWidth(myRef.current.clientWidth);
  //     setHeight(myRef.current.clientHeight);
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('click', updateWidth);
  //   updateWidth();
  //   return () => {
  //     window.removeEventListener('click', updateWidth);
  //   };
  // }, [myRef]);

  // ! ---------------- get coordinat ------------------
  const handleMouseClick = (event) => {
    // 👇 Get mouse position relative to element

    const localX = event.clientX - event.target.offsetLeft;
    const localY = event.clientY - event.target.offsetTop;

    setPosition({ x: localX, y: localY });
  };
  useEffect(() => {
    setXPosition(
      matches
        ? ((position.x - 40) / width) * 100
        : ((position.x - 400) / width) * 100
    );
    setYPosition(((position.y - 135) / height) * 100);
  }, [position, width, height]);

  //! button submit
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (capacity !== '' && name !== '' && outletDimensionRoomImageId !== null) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [capacity, name, outletDimensionRoomImageId]);

  const handleSubmit = () => {
    let datas = {
      name: name,
      capacity: Number(capacity),
      outlet_dimension_room_image_id: outletDimensionRoomImageId,
      x_position: xPosition,
      y_position: yPosition,
    };

    const saveTable = async () => {
      setIsLoading(true);
      try {
        const data = await addNewTable(datas);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    saveTable();
    navigate(-1);
  };

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = listImageArea?.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2,
        p: 2,
      }}
    >
      <Box>
        <DrawerHeader />
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12}>
            <CustomCard>
              <Box>
                <Typography sx={{ fontSize: '14px' }}>
                  Click the table to select position
                </Typography>
                <Box sx={{ display: 'flex', gap: 2, m: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        width: '10px',
                        height: '10px',
                        background: '#4FD240',
                        borderRadius: '3px',
                      }}
                    ></Box>
                    <Typography sx={{ fontSize: '8px' }}> Selected</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box
                      sx={{
                        width: '10px',
                        height: '10px',
                        background: '#F33A3A',
                        borderRadius: '3px',
                      }}
                    ></Box>
                    <Typography sx={{ fontSize: '8px' }}> Existing</Typography>
                  </Box>
                </Box>
              </Box>
              <Carousel
                index={listImageArea?.length - 1}
                autoPlay={true}
                navButtonsAlwaysInvisible={true}
                changeOnFirstRender={true}
                onChange={() => {
                  clicked && test();
                }}
                interval={3600000}
              >
                {listImageArea?.length !== 0 ? (
                  listImageArea?.map((image, index) => {
                    return (
                      <Box
                        key={index}
                        ref={myRef}
                        sx={{
                          width: '100%',
                          // height: '100%',
                          position: 'relative',
                          borderRadius: '12px',
                          backgroundImage: `url(${image.imageUrl})`,
                          aspectRatio: '2/1',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                        }}
                        onChange={() => {
                          setCliked(false);
                        }}
                        onClick={(event) => {
                          setOutletDimensionRoomImageId(image?.id);
                          handleMouseClick(event);
                          setPreview(true);

                          setCliked(false);
                        }}
                      >
                        {preview && (
                          <Box
                            sx={{
                              background: '#4FD240',
                              minWidth: '41px',
                              height: '16px',
                              borderRadius: '4px',
                              // border: '1px solid #000',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              position: 'absolute',
                              left: `${xPosition}%`,
                              top: `${yPosition}%`,
                            }}
                            onClick={(event) => event.stopPropagation()}
                          >
                            <Typography
                              sx={{
                                fontSize: '8px',
                                fontWeight: 600,
                                color: '#FFFFFF',
                              }}
                            >
                              {name}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    );
                  })
                ) : (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '12px',
                      backgroundImage: `url("https://dev-enak.x5.com.au/storage/products/08318b61295c4f3b8b09ea6be0494280.jpg")`,
                      aspectRatio: '2/1',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      position: 'relative',
                    }}
                  ></Box>
                )}
              </Carousel>
            </CustomCard>
          </Grid>

          {/* name */}
          <Grid item xs={6}>
            <CustomInput
              label="Table Name*"
              readOnly={!permissions.includes('add-outlet-table')}
              fullWidth
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                setErrorName(false);
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
              error={errorName}
              helperText={errorName && 'required'}
            />
          </Grid>

          {/* capacity */}
          <Grid item xs={6}>
            <CustomInput
              type="number"
              label="Capacity*"
              readOnly={!permissions.includes('add-outlet-table')}
              fullWidth
              value={capacity}
              onChange={(event) => {
                setCapacity(event.target.value);
                setErrorCapacity(false);
              }}
              sx={{
                '& .MuiOutlinedInput-input': {
                  paddingInline: '14px',
                  fontSize: '0.875rem',
                },
              }}
              error={errorCapacity}
              helperText={errorCapacity && 'required'}
            />
          </Grid>
        </Grid>
      </Box>

      {/* button submit */}
      <CustomButton
        onClick={handleSubmit}
        disabled={!isDisabled}
        type="submit"
        fullWidth
        variant="contained"
      >
        Save
      </CustomButton>
    </Box>
  );
};
export default TableAddForms;
