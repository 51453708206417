import axios from 'axios';
import { adminAxios } from '../helpers/api';

export const uploadImage = (data) => {
  const accessToken = localStorage.getItem('accessToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/outlet-table/images`,
    data: data,
    headers: {
      Access: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const uploadVideo = (data) => {
  const accessToken = localStorage.getItem('accessToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/outlet-table/videos`,
    data: data,
    headers: {
      Access: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const uploadTable = (data) => adminAxios.post('outlet-table', data);

export const addNewTable = (data) => adminAxios.post('outlet-table', data);
export const getArea = () => adminAxios.get('outlet-room');
export const getTable = (idArea) =>
  adminAxios.get(`outlet-room/${idArea}/table-list`);
export const getDetailTable = (idTable) =>
  adminAxios.get(`outlet-table/${idTable}`);
export const updateTable = (idTable, data) =>
  adminAxios.put(`outlet-table/${idTable}`, data);
export const deleteTable = (id) => adminAxios.delete(`outlet-table/${id}`);
