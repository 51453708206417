import CustomCard from './shared/CustomCard';
import { Box, Grid, Skeleton, Switch, Typography } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProducts, patchProductStatus } from '../api/product';
import { numberWithCommas } from '../helpers/general';

const ProductCards = ({
  products,
  setProducts,
  hasMore,
  setHasMore,
  keyword,
  permissions,
}) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(2);

  const fetchMoreProducts = async ({ keyword, page }) => {
    const {
      data: { products: newProducts, nextPageUrl },
    } = await getProducts({ keyword, page });

    setProducts([...products, ...newProducts]);

    if (nextPageUrl) {
      setHasMore(true);
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  const handleChangeStatus = async (productId) => {
    let tempProducts = [...products];
    let tempProduct = tempProducts.find(({ id }) => id === productId);
    tempProduct.status = !tempProduct.status;

    await patchProductStatus({
      id: productId,
      status: +tempProduct.status,
    });
    setProducts(tempProducts);
  };

  return (
    <InfiniteScroll
      dataLength={products.length}
      next={() => fetchMoreProducts({ keyword, page })}
      hasMore={hasMore}
      loader={<Skeleton variant='text' sx={{ mt: 1 }} />}
      style={{ overflow: 'visible' }}
    >
      {products.map((product, index) => (
        <CustomCard
          key={product.id}
          sx={{ cursor: 'pointer', mt: index !== 0 && 2 }}
          onClick={() => navigate(`edit/${product.id}`)}
        >
          <Grid container spacing={1.5}>
            <Grid item xs={2.25}>
              <Box
                component='img'
                src={product.imageUrl}
                sx={{
                  width: 1,
                  borderRadius: '12px',
                  aspectRatio: '1/1',
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '12px',
                  textTransform: 'capitalize',
                  lineHeight: 1.25,
                }}
              >
                {product.name || '-'}
              </Typography>
              <Typography
                sx={{
                  fontSize: '10px',
                  mt: 0.25,
                }}
              >
                Rp{numberWithCommas(product.defaultPrice) || '-'}
              </Typography>
              {product.orderTypes?.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                    mt: 0.25,
                  }}
                >
                  {product.orderTypes?.map(
                    (orderType, index) =>
                      orderType.imageUrl && (
                        <Box
                          key={index}
                          component='img'
                          src={orderType.imageUrl}
                          sx={{ height: '18px' }}
                        />
                      )
                  )}
                </Box>
              )}
            </Grid>
            <Grid item xs='auto'>
              {permissions.includes('adjust-product-status') && (
                <Switch
                  sx={{ mt: -1, mr: -1 }}
                  checked={Boolean(product.status)}
                  onChange={() => {
                    handleChangeStatus(product.id);
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              )}
            </Grid>
          </Grid>
        </CustomCard>
      ))}
    </InfiniteScroll>
  );
};
export default ProductCards;
