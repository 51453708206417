import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../components/shared/CustomAppBarBack';
import ProductCategoryAddForms from '../components/ProductCategoryAddForms';

const ProductCategoryAdd = ({ matches }) => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title="Add Category" />
      <Container maxWidth="lg" disableGutters>
        <ProductCategoryAddForms matches={matches} />
      </Container>
    </Box>
  );
};
export default ProductCategoryAdd;
