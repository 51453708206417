import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { getOrders } from '../api/order';
import { imageLoadingPlaceholder } from '../assets/images';
import { invoiceStatusLabel, numberWithCommas } from '../helpers/general';
import CustomCard from './shared/CustomCard';

const OrderCards = ({
  orders,
  setOrders,
  hasMore,
  setHasMore,
  keyword,
  startDate,
  endDate,
}) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(2);

  const fetchMoreOrders = async ({ keyword, startDate, endDate, page }) => {
    const {
      data: {
        histories: { invoices: newInvoices },
        nextPageUrl,
      },
    } = await getOrders({
      startDate,
      endDate,
      page,
    });

    setOrders([...orders, ...newInvoices]);

    if (nextPageUrl) {
      setHasMore(true);
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <InfiniteScroll
        dataLength={orders.length}
        next={() => fetchMoreOrders({ keyword, startDate, endDate, page })}
        hasMore={hasMore}
        loader={<Skeleton variant="text" sx={{ mt: 1 }} />}
        style={{ overflow: 'visible' }}
      >
        {orders.map((order, index) => (
          <CustomCard
            key={order.id}
            sx={{ mt: index && 2, p: 0, flexGrow: 1 }}
            onClick={() => navigate(`${order.id}`)}
          >
            <Box sx={{ p: 1.5 }}>
              <Grid container spacing={1.5}>
                <Grid item xs="auto">
                  <Box
                    sx={{
                      width: '22px',
                      display: 'flex',
                      alignItems: 'center',
                      height: 1,
                    }}
                  >
                    <Box
                      component="img"
                      src={order.orderType?.imageUrl || imageLoadingPlaceholder}
                      sx={{ width: 1, borderRadius: '50%' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                      noWrap
                      sx={{
                        fontWeight: 600,
                        fontSize: '12px',
                        lineHeight: 1.25,
                      }}
                    >
                      #{order.id}
                    </Typography>
                    <Typography
                      sx={{
                        color: invoiceStatusLabel(order.status).color,
                        bgcolor: invoiceStatusLabel(order.status).bgcolor,
                        borderRadius: '5px',
                        fontSize: '8px',
                        fontWeight: 600,
                        p: '3px 6px',
                        lineHeight: 1,
                      }}
                    >
                      {invoiceStatusLabel(order.status).name}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{ color: '#9E9D9D', fontSize: '9px', fontWeight: 500 }}
                  >
                    {dayjs(order.createdAt).format('DD/MMM/YYYY, hh:mm')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box
              sx={{
                p: 1.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 1.5,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                }}
              >
                <Box
                  component="img"
                  src={
                    order.orders[0].product?.imageUrl || imageLoadingPlaceholder
                  }
                  sx={{ width: '45px', borderRadius: '8px' }}
                />
                <Box>
                  <Typography sx={{ fontWeight: 600, fontSize: '11px' }}>
                    {order.orders[0].product?.name}
                  </Typography>
                  {order.orders.length > 1 && (
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: '8px',
                        color: 'info.main',
                      }}
                    >
                      +{order.orders.length - 1} more item
                      {order.orders.length - 1 > 1 && 's'}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                Rp{numberWithCommas(order.grandTotal)}
              </Typography>
            </Box>
          </CustomCard>
        ))}
      </InfiniteScroll>
    </Box>
  );
};
export default OrderCards;
