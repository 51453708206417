import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { Fragment, useState } from 'react';
import { DrawerHeader } from '../../helpers/general';
import CustomDrawer from './CustomDrawer';

const CustomAppBarFilter = ({ filters }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ color: '#000' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexGrow: 1,
              ml: 1,
            }}
          >
            {filters.map((filter, index) => (
              <Fragment key={index}>{filter}</Fragment>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <DrawerHeader />
      <CustomDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
export default CustomAppBarFilter;
