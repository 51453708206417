import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

export const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const adminAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const controller = new AbortController();

authAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    if (config.data) config.data = decamelizeKeys(config.data);
    if (config.params) config.params = decamelizeKeys(config.params);
    config.signal = controller.signal;

    return config;
  },
  (error) => Promise.reject(error)
);

authAxios.interceptors.response.use(
  (response) => camelizeKeys(response.data),
  (error) => Promise.reject(error)
);

adminAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    if (config.headers['Content-Type'] === 'application/json' && config.data)
      config.data = decamelizeKeys(config.data);
    if (config.params) config.params = decamelizeKeys(config.params);
    config.signal = controller.signal;

    return config;
  },
  (error) => Promise.reject(error)
);

adminAxios.interceptors.response.use(
  (response) => camelizeKeys(response.data),
  (error) => {
    if (error?.response?.status === 401) {
      controller.abort();
      localStorage.removeItem('accessToken');
      alert('Your session has expired');
      window.location.reload();
    }

    return Promise.reject(error);
  }
);
