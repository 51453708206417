import { Box, Grid, Skeleton, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import {
  getProductCategories,
  patchProductCategoryStatus,
} from '../api/productCategory';
import { maxFontSize, responsiveFontSize } from '../helpers/general';
import CustomCard from './shared/CustomCard';
import {
  changeNameCategory,
  changeProductCategoryId,
} from '../store/category/subCategory/subCategory';
import { useDispatch } from 'react-redux';

const ProductCategoryCards = ({
  categories,
  setCategories,
  hasMore,
  setHasMore,
  keyword,
  matches,
  permissions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(2);

  const fetchMoreProductCategories = async ({ keyword, page }) => {
    const {
      data: { productCategories, nextPageUrl },
    } = await getProductCategories({ keyword, page });

    setCategories([...categories, ...productCategories]);

    if (nextPageUrl) {
      setHasMore(true);
      setPage(page + 1);
    } else {
      setHasMore(false);
    }
  };

  const handleChangeCategoryStatus = async (categoryId) => {
    let tempCategories = [...categories];
    let tempCategory = tempCategories.find(({ id }) => id === categoryId);
    tempCategory.status = !tempCategory.status;

    await patchProductCategoryStatus({
      id: categoryId,
      status: +tempCategory.status,
    });
    setCategories(tempCategories);
  };

  return (
    <InfiniteScroll
      dataLength={categories.length}
      next={() => fetchMoreProductCategories({ keyword, page })}
      hasMore={hasMore}
      loader={<Skeleton variant="text" sx={{ mt: 1 }} />}
      style={{ overflow: 'visible' }}
    >
      {categories.map((category, index) => (
        <CustomCard key={category.id} sx={{ mt: index !== 0 && 3 }}>
          <Grid container spacing={1.5}>
            <Grid item xs={2.25}>
              <Box
                component="img"
                src={category.imageUrl}
                sx={{
                  width: 1,
                  borderRadius: '12px',
                  aspectRatio: '1/1',
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: matches
                    ? responsiveFontSize(16) + 'vw'
                    : maxFontSize(16) + 'px',
                  textTransform: 'capitalize',
                  lineHeight: 1.25,
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`edit/${category.id}`);
                }}
              >
                {category.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: matches
                    ? responsiveFontSize(12) + 'vw'
                    : maxFontSize(12) + 'px',
                }}
              >
                Station: {category.station?.name || '-'}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#4FD240',
                  mt: 1,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  dispatch(changeProductCategoryId(category.id));
                  dispatch(changeNameCategory(category?.name));
                  navigate(`detail/${category.id}`);
                }}
              >
                Sub Category
              </Typography>
            </Grid>
            <Grid item xs="auto">
              {permissions.includes('adjust-product-category-status') && (
                <Switch
                  sx={{ mt: -1, mr: -1 }}
                  checked={Boolean(category.status)}
                  onChange={() => {
                    handleChangeCategoryStatus(category.id);
                  }}
                  onClick={(event) => event.stopPropagation()}
                />
              )}
            </Grid>
          </Grid>
        </CustomCard>
      ))}
    </InfiniteScroll>
  );
};
export default ProductCategoryCards;
