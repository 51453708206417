import { createSlice } from '@reduxjs/toolkit';

export const detailAreaState = createSlice({
  name: 'detailArea',
  initialState: {
    idArea: null,
    name: '',
    column: null,
    row: null,
    indexGrids: [],
  },
  reducers: {
    changeIdArea: (state, action) => {
      state.idArea = action.payload;
    },
    changeName: (state, action) => {
      state.name = action.payload;
    },
    changeColumn: (state, action) => {
      state.column = action.payload;
    },
    changeRow: (state, action) => {
      state.row = action.payload;
    },
    changeIdexGrid: (state, action) => {
      state.indexGrids = action.payload;
    },
  },
});

export const {
  changeIdArea,
  changeName,
  changeColumn,
  changeRow,
  changeIdexGrid,
} = detailAreaState.actions;

export const getidArea = (state) => state.detailArea.idArea;
export const getName = (state) => state.detailArea.name;
export const getColumn = (state) => state.detailArea.column;
export const getRow = (state) => state.detailArea.row;
export const getIndexGrid = (state) => state.detailArea.indexGrids;

export default detailAreaState.reducer;
