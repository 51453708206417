import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const CustomAppBarBack = ({ title }) => {
  const navigate = useNavigate();

  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ color: '#000' }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon color="primary" />
          </IconButton>
          <Typography sx={{ ml: 1, fontWeight: 600 }}>{title}</Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default CustomAppBarBack;
