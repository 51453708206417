import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomAppBar from '../components/shared/CustomAppBar';
import OrderTypeCards from '../components/OrderTypeCards';
import { getOrderTypes } from '../api/orderType';

const OrderType = ({ permissions }) => {
  const [orderTypes, setOrderTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrderTypes = async () => {
      try {
        setIsLoading(true);
        const { data: orderTypes } = await getOrderTypes();
        setOrderTypes(orderTypes);
      } catch {
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderTypes();
  }, []);

  return (
    <Box>
      <CustomAppBar title='Order Type' />
      <Container maxWidth='lg' disableGutters>
        <Box sx={{ p: 2 }}>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '500px',
                transform: 'scaleX(-1)',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {!isLoading && orderTypes.length === 0 && (
            <Typography sx={{ fontSize: '0.875rem' }}>
              No order types available
            </Typography>
          )}

          {!isLoading && orderTypes.length > 0 && (
            <OrderTypeCards
              orderTypes={orderTypes}
              setOrderTypes={setOrderTypes}
              permissions={permissions}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};
export default OrderType;
