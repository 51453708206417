import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../../../../components/shared/CustomAppBarBack';
import TableAddForms from '../../../../components/TableAddForms';

const AddTableMenu = ({ matches, permissions }) => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title="Add Table" />
      <Container maxWidth="lg" disableGutters>
        <TableAddForms matches={matches} permissions={permissions} />
      </Container>
    </Box>
  );
};
export default AddTableMenu;
