import { Drawer } from '@mui/material';

const CustomBottomDrawer = ({ isOpen, setIsOpen, children }) => {
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      PaperProps={{
        style: {
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          width: '100%',
        },
      }}
    >
      {children}
    </Drawer>
  );
};
export default CustomBottomDrawer;
