import { Dialog } from '@mui/material';

const CustomDialog = ({ isOpen, onClose, children }) => {
  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      {children}
    </Dialog>
  );
};
export default CustomDialog;
