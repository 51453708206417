import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../components/shared/CustomAppBarBack';
import BannerEditForms from '../components/BannerEditForms';

const BannerEdit = () => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title="Edit Banner" />
      <Container maxWidth="lg" disableGutters>
        <BannerEditForms />
      </Container>
    </Box>
  );
};
export default BannerEdit;
