import { useState, useEffect } from 'react';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  useTheme,
  Box,
  Typography,
  Button,
} from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { ReactComponent as OrdersIcon } from '../../assets/icons/orders-icon.svg';
import { ReactComponent as StationsIcon } from '../../assets/icons/stations-icon.svg';
import { ReactComponent as TableIcon } from '../../assets/icons/table-icon.svg';
import { ReactComponent as CategoryIcon } from '../../assets/icons/category-icon.svg';
import { ReactComponent as OperatingHourIcon } from '../../assets/icons/operating-hour-icon.svg';
import { ReactComponent as OrderTypeIcon } from '../../assets/icons/order-type-icon.svg';
import { ReactComponent as PaymentIcon } from '../../assets/icons/payment-icon.svg';
import { ReactComponent as ProductIcon } from '../../assets/icons/product-icon.svg';
import { ReactComponent as BannerIcon } from '../../assets/icons/banner-icon.svg';
import { getProfileDetailAdmin } from '../../api/auth';
import { enakLogo } from '../../assets/images';

const CustomDrawer = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logOut, permissions } = useAuth();
  const theme = useTheme();

  const [profileDetail, setProfileDetail] = useState(null);

  useEffect(() => {
    getProfileDetailAdmin()
      .then((res) => {
        setProfileDetail(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Drawer open={isOpen} onClose={() => setIsOpen(!isOpen)}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          gap: 2,
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box component="img" src={enakLogo} sx={{ height: '60px' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography sx={{ fontSize: '16px', fontWeight: 700, lineHeight: 1 }}>
            {profileDetail?.name}
          </Typography>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 400, color: '#848589' }}
          >
            {profileDetail?.roles.join(', ')}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#848589',
              lineHeight: 1.25,
            }}
          >
            {profileDetail?.outlet?.name}
          </Typography>
        </Box>
      </Box>
      <Divider variant="middle" />
      <List sx={{ flex: 1 }}>
        {permissions.includes('view-outlet-invoice') && (
          <ListItem disablePadding id="order">
            <ListItemButton
              sx={{ bgcolor: location?.pathname === '/order' && '#4FD2401A' }}
              component={Link}
              to="/order"
            >
              <ListItemIcon>
                <OrdersIcon
                  fill={
                    location?.pathname === '/order'
                      ? theme.palette.primary.main
                      : 'black'
                  }
                  width="24px"
                  height="24px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Order"
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color:
                      location?.pathname === '/order'
                        ? 'primary.main'
                        : 'common.black',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {permissions.includes('view-station') && (
          <ListItem disablePadding id="station">
            <ListItemButton
              component={Link}
              to="/station"
              sx={{ bgcolor: location?.pathname === '/station' && '#4FD2401A' }}
            >
              <ListItemIcon>
                <StationsIcon
                  fill={
                    location?.pathname === '/station'
                      ? theme.palette.primary.main
                      : 'black'
                  }
                  width="24px"
                  height="24px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Station"
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color:
                      location?.pathname === '/station'
                        ? 'primary.main'
                        : 'common.black',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}

        {/* area */}
        {permissions.includes('view-outlet-area') && (
          <ListItem disablePadding id="area">
            <ListItemButton
              component={Link}
              to="/area"
              sx={{ bgcolor: location?.pathname === '/area' && '#4FD2401A' }}
            >
              <ListItemIcon>
                <TableIcon
                  fill={
                    location?.pathname === '/area'
                      ? theme.palette.primary.main
                      : 'black'
                  }
                  width="18px"
                  height="18px"
                />
              </ListItemIcon>

              <ListItemText
                primary="Area"
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color:
                      location?.pathname === '/area'
                        ? 'primary.main'
                        : 'common.black',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}

        {permissions.includes('view-product-category') && (
          <ListItem disablePadding id="product-category">
            <ListItemButton
              component={Link}
              to="/product-category"
              sx={{
                bgcolor:
                  location?.pathname === '/product-category' && '#4FD2401A',
              }}
            >
              <ListItemIcon>
                <CategoryIcon
                  fill={
                    location?.pathname === '/product-category'
                      ? theme.palette.primary.main
                      : 'black'
                  }
                  width="24px"
                  height="24px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Category"
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color:
                      location?.pathname === '/product-category'
                        ? 'primary.main'
                        : 'common.black',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {permissions.includes('view-product') && (
          <ListItem disablePadding id="product">
            <ListItemButton
              component={Link}
              to="/product"
              sx={{ bgcolor: location?.pathname === '/product' && '#4FD2401A' }}
            >
              <ListItemIcon>
                <ProductIcon
                  fill={
                    location?.pathname === '/product'
                      ? theme.palette.primary.main
                      : 'black'
                  }
                  width="22px"
                  height="22px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Product"
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color:
                      location?.pathname === '/product'
                        ? 'primary.main'
                        : 'common.black',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {permissions.includes('view-outlet-payment-method') && (
          <ListItem disablePadding id="payment-method">
            <ListItemButton
              component={Link}
              to="/payment-method"
              sx={{
                bgcolor:
                  location?.pathname === '/payment-method' && '#4FD2401A',
              }}
            >
              <ListItemIcon>
                <PaymentIcon
                  fill={
                    location?.pathname === '/payment-method'
                      ? theme.palette.primary.main
                      : 'black'
                  }
                  width="24px"
                  height="24px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Payment Method"
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color:
                      location?.pathname === '/payment-method'
                        ? 'primary.main'
                        : 'common.black',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {permissions.includes('view-outlet-banner') && (
          <ListItem disablePadding id="banner">
            <ListItemButton
              component={Link}
              to="/banner"
              sx={{ bgcolor: location?.pathname === '/banner' && '#4FD2401A' }}
            >
              <ListItemIcon>
                <BannerIcon
                  fill={
                    location?.pathname === '/banner'
                      ? theme.palette.primary.main
                      : 'black'
                  }
                  width="26px"
                  height="26px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Banner"
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color:
                      location?.pathname === '/banner'
                        ? 'primary.main'
                        : 'common.black',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {permissions.includes('view-outlet-order-type') && (
          <ListItem disablePadding id="order-type">
            <ListItemButton
              component={Link}
              to="/order-type"
              sx={{
                bgcolor: location?.pathname === '/order-type' && '#4FD2401A',
              }}
            >
              <ListItemIcon>
                <OrderTypeIcon
                  fill={
                    location?.pathname === '/order-type'
                      ? theme.palette.primary.main
                      : 'black'
                  }
                  width="28px"
                  height="28px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Order Type"
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color:
                      location?.pathname === '/order-type'
                        ? 'primary.main'
                        : 'common.black',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        {permissions.includes('view-outlet-operating-hour') && (
          <ListItem disablePadding id="operating-hour">
            <ListItemButton
              component={Link}
              to="/operating-hour"
              sx={{
                bgcolor:
                  location?.pathname === '/operating-hour' && '#4FD2401A',
              }}
            >
              <ListItemIcon>
                <OperatingHourIcon
                  fill={
                    location?.pathname === '/operating-hour'
                      ? theme.palette.primary.main
                      : 'black'
                  }
                  width="24px"
                  height="24px"
                />
              </ListItemIcon>
              <ListItemText
                primary="Operating Hour"
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    color:
                      location?.pathname === '/operating-hour'
                        ? 'primary.main'
                        : 'common.black',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider variant="middle" sx={{ mb: 1 }} />
      <ListItem disablePadding sx={{ mb: 1 }}>
        <ListItemButton
          onClick={() => {
            logOut();
            navigate('/');
          }}
        >
          <ListItemIcon>
            <IconButton sx={{ p: 0 }}>
              <LogoutIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{ sx: { fontWeight: 600 } }}
          />
        </ListItemButton>
      </ListItem>
    </Drawer>
  );
};
export default CustomDrawer;
