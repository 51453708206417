import { Autocomplete, TextField } from '@mui/material';

const CustomAutocomplete = ({
  label,
  placeholder,
  error,
  helperText,
  ...props
}) => {
  return (
    <Autocomplete
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};
export default CustomAutocomplete;
