import { createSlice } from '@reduxjs/toolkit';
import { getSubCategory } from '../../../api/productCategory';

export const subCategoryState = createSlice({
  name: 'subCategory',
  initialState: {
    productCategoryId: null,
    categoryName: '',
    categories: [],
  },
  reducers: {
    loadCategories: (state, action) => {
      state.categories = action.payload;
    },
    changeProductCategoryId: (state, action) => {
      state.productCategoryId = action.payload;
    },
    changeNameCategory: (state, action) => {
      state.categoryName = action.payload;
    },
  },
});

export const { loadCategories, changeProductCategoryId, changeNameCategory } =
  subCategoryState.actions;

export const getIdCategory = (state) => state.subCategory.productCategoryId;
export const getListCategory = (state) => state.subCategory.categories;
export const getCategoryName = (state) => state.subCategory.categoryName;

// ! -------------------- API ---------------
export const getAllDataSubCategory = (params) => (dispatch) => {
  const fetchSubCategory = async () => {
    try {
      const data = await getSubCategory(params);
      dispatch(loadCategories(data.data));
    } catch (err) {
      console.error(err);
    }
  };
  fetchSubCategory();
};

export default subCategoryState.reducer;
