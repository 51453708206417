import { adminAxios } from '../helpers/api';

export const postStation = ({ name }) => adminAxios.post('stations', { name });

export const getStations = (props) =>
  adminAxios.get('stations', {
    params: {
      keyword: props?.keyword || null,
      page: props?.page,
      pageSize: props?.pageSize || 30,
    },
  });

export const patchStationName = ({ id, name }) =>
  adminAxios.patch(`stations/${id}`, { name });
