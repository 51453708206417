import { adminAxios } from '../helpers/api';

export const getProducts = ({
  keyword,
  productCategoryId,
  page,
  pageSize = 10,
}) =>
  adminAxios.get('products', {
    params: {
      keyword: keyword || null,
      productCategoryId: productCategoryId || null,
      page,
      pageSize,
    },
  });

export const patchProductStatus = ({ id, status }) =>
  adminAxios.patch(`products/${id}/set-status`, { status });

export const getProductById = ({ id }) => adminAxios.get(`products/${id}`);

export const postProductImage = (data) =>
  adminAxios.post(`products/images`, data);

export const patchProductById = ({ id, data }) =>
  adminAxios.patch(`products/${id}`, data);

export const deleteProductById = ({ id }) =>
  adminAxios.delete(`products/${id}`);

export const postProduct = (data) => adminAxios.post('products', data);
