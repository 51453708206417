import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import { DrawerHeader } from '../../helpers/general';
import CustomDrawer from './CustomDrawer';

const CustomAppBar = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ color: '#000' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography sx={{ ml: 1, fontWeight: 600 }}>{title}</Typography>
        </Toolbar>
      </AppBar>
      <DrawerHeader />
      <CustomDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
export default CustomAppBar;
