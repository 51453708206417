import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../components/shared/CustomAppBarBack';
import ProductEditForms from '../components/ProductEditForms';

const ProductEdit = ({ matches, permissions }) => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title='Edit Product' />
      <Container maxWidth='lg' disableGutters>
        <ProductEditForms matches={matches} permissions={permissions} />
      </Container>
    </Box>
  );
};
export default ProductEdit;
