import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../../../components/shared/CustomAppBarBack';
import SubCategoryEditForms from '../../../components/SubCategoryEditForms';

const EditSubCategoryMenu = ({ matches, permissions }) => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title="Edit Sub Category" />
      <Container maxWidth="lg" disableGutters>
        <SubCategoryEditForms matches={matches} permissions={permissions} />
      </Container>
    </Box>
  );
};
export default EditSubCategoryMenu;
