import { Box, CircularProgress, Container, Typography } from '@mui/material';
import {
  Search as SearchIcon,
  Add as AddIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import CustomAppBarFilter from '../components/shared/CustomAppBarFilter';
import CustomInput from '../components/shared/CustomInput';
import CustomFab from '../components/shared/CustomFab';
import ProductCategoryCards from '../components/ProductCategoryCards';
import { getProductCategories } from '../api/productCategory';

const ProductCategory = ({ matches, permissions }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);

  const [keyword, setKeyword] = useState(searchParams.get('keyword') || '');
  const [savedKeyword, setSavedKeyword] = useState(
    searchParams.get('keyword') || ''
  );
  const debounceKeyword = useRef(
    debounce((nextValue) => {
      setSavedKeyword(nextValue);
    }, 1000)
  ).current;

  const handleChangeKeyword = (event) => {
    const { value } = event.target;
    value !== ''
      ? updatedSearchParams.set('keyword', value)
      : updatedSearchParams.delete('keyword');
    setSearchParams(updatedSearchParams, { replace: true });
    setKeyword(value);
    debounceKeyword(value);
  };

  const handleClearKeyword = () => {
    updatedSearchParams.delete('keyword');
    setSearchParams(updatedSearchParams, { replace: true });
    setKeyword('');
    debounceKeyword('');
  };

  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const fetchProductCategories = async ({ keyword }) => {
    try {
      setIsLoading(true);
      const {
        data: { productCategories, nextPageUrl },
      } = await getProductCategories({ keyword });
      setCategories(productCategories);

      if (nextPageUrl) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProductCategories({ keyword: savedKeyword });
  }, [savedKeyword]);

  const SearchField = (
    <CustomInput
      placeholder="Category"
      fullWidth
      value={keyword}
      onChange={handleChangeKeyword}
      startAdornment={<SearchIcon />}
      endAdornment={
        keyword !== '' && (
          <CloseIcon onClick={handleClearKeyword} sx={{ cursor: 'pointer' }} />
        )
      }
      sx={{ '& .MuiOutlinedInput-input': { paddingBlock: '8px' } }}
    />
  );

  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarFilter title="Station" filters={[SearchField]} />
      <Container maxWidth="lg" disableGutters>
        <Box sx={{ p: 2 }}>
          <Box>
            {isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '500px',
                  transform: 'scaleX(-1)',
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {!isLoading && categories.length === 0 && (
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                No product category found
              </Typography>
            )}

            {!isLoading && categories.length > 0 && (
              <ProductCategoryCards
                categories={categories}
                setCategories={setCategories}
                hasMore={hasMore}
                setHasMore={setHasMore}
                keyword={keyword}
                matches={matches}
                permissions={permissions}
              />
            )}
          </Box>

          {permissions.includes('add-product-category') && (
            <CustomFab
              sx={{ position: 'fixed', bottom: 16, right: 16 }}
              color="primary"
              icon={<AddIcon />}
              onClick={() => navigate('add')}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};
export default ProductCategory;
