import { adminAxios } from '../helpers/api';

export const getOrders = ({
  keyword,
  sortAmountDirection,
  orderTypeIds,
  statuses,
  startDate,
  endDate,
  page,
}) =>
  adminAxios.get(
    'invoices?include=invoicePriceDetails,orders.product,paymentMethod,orderType',
    {
      params: {
        keyword,
        sortAmountDirection,
        orderTypeIds,
        statuses,
        startDate,
        endDate,
        page,
        pageSize: 10,
      },
    }
  );
