import { Box, Typography } from '@mui/material';
import {
  invoiceCountIcon,
  orderCountIcon,
  revenueTotalIcon,
} from '../assets/images';
import { numberWithCommas } from '../helpers/general';

const OrderSummary = ({ summary }) => {
  const SummaryCard = ({ icon, name, value }) => (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        borderRadius: '8px',
        bgcolor: 'rgba(0,0,0,0.05)',
        p: 1,
        flexGrow: 1,
      }}
    >
      <Box component="img" src={icon} sx={{ width: '22px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Typography align="center" sx={{ fontWeight: 600, fontSize: '10px' }}>
          {name}
        </Typography>
        <Typography align="center" sx={{ fontWeight: 800, fontSize: '14px' }}>
          {value}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: 2,
      }}
    >
      <SummaryCard
        icon={invoiceCountIcon}
        name="Invoice(s)"
        value={summary?.invoiceCount || 0}
      />
      <SummaryCard
        icon={orderCountIcon}
        name="Order(s)"
        value={summary?.orderCount || 0}
      />
      <SummaryCard
        icon={revenueTotalIcon}
        name="Revenue"
        value={`Rp${numberWithCommas(summary?.totalAmount || 0)}`}
      />
    </Box>
  );
};
export default OrderSummary;
