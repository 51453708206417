import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../../../../components/shared/CustomAppBarBack';
import TableEditForms from '../../../../components/TableEditForms';

const EditTable = ({ matches, permissions }) => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title="Edit Table" />
      <Container maxWidth="lg" disableGutters>
        <TableEditForms matches={matches} permissions={permissions} />
      </Container>
    </Box>
  );
};
export default EditTable;
