import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import CustomCard from './shared/CustomCard';
import { numberWithCommas } from '../helpers/general';

const OrderPaymentDetail = ({ orderDetails }) => {
  console.log(orderDetails);
  return (
    <Box>
      <Typography sx={{ fontSize: '16px', fontWeight: 600, mb: 1 }}>
        Payment Detail
      </Typography>

      <CustomCard sx={{ p: 0 }}>
        <Box
          sx={{
            p: 1.5,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}
          >
            <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
              Subtotal
            </Typography>
            <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
              {numberWithCommas(orderDetails?.subtotal)}
            </Typography>
          </Box>
          {orderDetails?.invoicePriceDetails?.map((invoice, index) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 1,
                }}
                key={index}
              >
                <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
                  {invoice?.name}
                </Typography>
                <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
                  {numberWithCommas(invoice?.amount)}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, p: 1.5 }}>
            Grand Total
          </Typography>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, p: 1.5 }}>
            Rp{numberWithCommas(orderDetails?.grandTotal)}
          </Typography>
        </Box>
      </CustomCard>
    </Box>
  );
};

export default OrderPaymentDetail;
