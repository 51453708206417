import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Provider } from 'react-redux';
import store from './store/store';

const theme = createTheme({
  palette: {
    primary: { main: '#4FD240', contrastText: '#fff' },
    info: { main: '#848589' },
    error: { main: '#F26C6D' },
    background: { default: '#fff' },
  },
  typography: { fontFamily: ['Inter', 'sans-serif'].join(',') },
  components: {
    MuiAppBar: {
      defaultProps: { elevation: 0 },
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: '#000',
          boxShadow: '0 2px 4px 0 #ADADAD40',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: { paper: { width: 280 } },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          '& .MuiSvgIcon-root': {
            color: '#B7AEAE',
          },
        },
        input: { paddingInline: '8px' },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: { backgroundColor: '#fff' },
      },
    },
    MuiButton: {
      defaultProps: { disableElevation: true },
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'initial',
          padding: '8px 20px',
          fontSize: '0.875rem',
          lineHeight: 1.4375,
        },
        startIcon: {
          '& .MuiSvgIcon-root': { fontSize: '1rem' },
        },
      },
    },
    MuiDialog: {
      styleOverrides: { paper: { borderRadius: '12px' } },
    },
    MuiDialogTitle: {
      styleOverrides: { root: { padding: '16px' } },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '16px',
          overflow: 'visible',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: { root: { padding: '0 16px 16px 16px' } },
    },
    MuiListItemIcon: {
      styleOverrides: { root: { minWidth: '36px' } },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '8px',
          margin: '0 8px',
          borderRadius: '8px',
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Provider store={store}>
        <AuthProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </BrowserRouter>
        </AuthProvider>
      </Provider>
    </LocalizationProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
