import { Box, Switch, Typography } from '@mui/material';
import { patchOrderTypeStatus } from '../api/orderType';
import CustomCard from './shared/CustomCard';

const OrderTypeCards = ({ orderTypes, setOrderTypes, permissions }) => {
  const handleChangeSwitch = async (event, orderTypeId) => {
    let tempOrderTypes = [...orderTypes];
    let tempOrderType = tempOrderTypes.find(({ id }) => id === orderTypeId);
    tempOrderType.status = !tempOrderType.status;
    await patchOrderTypeStatus({
      id: orderTypeId,
      status: +tempOrderType.status,
    });
    setOrderTypes(tempOrderTypes);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {orderTypes.map((orderType, index) => (
        <CustomCard
          key={orderType.id}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '0.875rem',
              textTransform: 'capitalize',
            }}
          >
            {orderType.name}
          </Typography>
          {permissions.includes('adjust-outlet-order-type-status') && (
            <Switch
              checked={Boolean(orderType.status)}
              onChange={(event) => handleChangeSwitch(event, orderType.id)}
              size='small'
            />
          )}
        </CustomCard>
      ))}
    </Box>
  );
};
export default OrderTypeCards;
