import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import CustomButton from './shared/CustomButton';
import {
  FilterList as FilterListIcon,
  CalendarToday as CalendarIcon,
  KeyboardArrowDown as ArrowDownIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { invoiceStatus } from '../helpers/enum';
import { useEffect, useState } from 'react';
import CustomBottomDrawer from './shared/CustomBottomDrawer';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { getOrderTypes } from '../api/orderType';

const OrderFilters = ({ date, status, sort, type }) => {
  const { ordersSort: savedSort, setOrdersSort: setSavedSort } = sort;

  const [ordersSort, setOrdersSort] = useState(savedSort);

  const handleChangeSort = (event) => {
    setOrdersSort(event.target.value);
  };

  const { ordersType: savedOrderTypes, setOrdersType: setSavedOrderTypes } =
    type;

  const [orderTypes, setOrderTypes] = useState([]);
  const [orderTypeFilters, setOrderTypeFilters] = useState([]);

  const fetchOrderTypes = async () => {
    const { data: fetchedOrderTypes } = await getOrderTypes();
    setOrderTypeFilters(
      fetchedOrderTypes.map(({ orderTypeId, name }) => ({
        id: orderTypeId,
        name,
      }))
    );
  };

  useEffect(() => {
    fetchOrderTypes();
  }, []);

  const handleChangeOrderType = (orderType) => {
    let tempOrderTypes = [...orderTypes];

    if (tempOrderTypes.includes(orderType)) {
      tempOrderTypes.splice(tempOrderTypes.indexOf(orderType), 1);
    } else {
      tempOrderTypes.push(orderType);
    }

    setOrderTypes(tempOrderTypes);
  };

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  useEffect(() => {
    if (isOpenFilter) {
      setOrderTypes(savedOrderTypes);
      setOrdersSort(savedSort);
    }
  }, [isOpenFilter]);

  const handleResetFilters = () => {
    setOrdersSort('desc');
    setOrderTypes([]);
  };

  const handleSubmitFilters = () => {
    setSavedSort(ordersSort);
    setSavedOrderTypes(orderTypes);
    setIsOpenFilter(!isOpenFilter);
  };

  const {
    startDate: savedStartDate,
    endDate: savedEndDate,
    setStartDate: setSavedStartDate,
    setEndDate: setSavedEndDate,
  } = date;

  const [startDate, setStartDate] = useState(savedStartDate);
  const [endDate, setEndDate] = useState(savedEndDate);

  const { ordersStatus, setOrdersStatus } = status;

  const [isOpenDate, setIsOpenDate] = useState(false);

  useEffect(() => {
    if (isOpenDate) {
      setStartDate(savedStartDate);
      setEndDate(savedEndDate);
    }
  }, [isOpenDate]);

  const handleSubmitDate = () => {
    setSavedStartDate(dayjs(startDate).format('YYYY-MM-DD'));
    setSavedEndDate(dayjs(endDate).format('YYYY-MM-DD'));
    setIsOpenDate(!isOpenDate);
  };

  const statusFilters = [
    { id: invoiceStatus.finished, name: 'Finished' },
    { id: invoiceStatus.paid, name: 'Paid' },
    { id: invoiceStatus.pending, name: 'Pending' },
    {
      id: invoiceStatus.customer_cancelled,
      name: 'Customer Cancelled',
    },
    {
      id: invoiceStatus.admin_cancelled,
      name: 'Admin Cancelled',
    },
    { id: invoiceStatus.expired, name: 'Expired' },
    { id: invoiceStatus.failed, name: 'Failed' },
  ];

  const handleChangeStatus = (status) => {
    let tempStatus = [...ordersStatus];

    if (tempStatus.includes(status)) {
      tempStatus.splice(tempStatus.indexOf(status), 1);
    } else {
      tempStatus.push(status);
    }

    setOrdersStatus(tempStatus);
  };

  return (
    <>
      <CustomBottomDrawer isOpen={isOpenFilter} setIsOpen={setIsOpenFilter}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '44.13px', height: '24px' }}>
            <CloseIcon
              sx={{ cursor: 'pointer' }}
              onClick={() => setIsOpenFilter(!isOpenFilter)}
            />
          </Box>

          <Typography sx={{ fontWeight: 600 }}>Filter</Typography>
          <Typography
            sx={{ fontWeight: 600, color: 'primary.main', cursor: 'pointer' }}
            onClick={handleResetFilters}
          >
            Reset
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            id="sort"
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <Typography
              sx={{ color: '#848589', fontSize: '14px', fontWeight: 600 }}
            >
              Sort by
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
                alignItems: 'center',
                height: '21px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                Amount: High to low
              </Typography>
              <Radio
                size="small"
                checked={ordersSort === 'desc'}
                onChange={handleChangeSort}
                value="desc"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
                alignItems: 'center',
                height: '21px',
              }}
            >
              <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                Amount: Low to high
              </Typography>
              <Radio
                size="small"
                checked={ordersSort === 'asc'}
                onChange={handleChangeSort}
                value="asc"
              />
            </Box>
            <Divider />
            <Typography
              sx={{ color: '#848589', fontSize: '14px', fontWeight: 600 }}
            >
              Order type
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                overflowX: 'auto',
                '&::-webkit-scrollbar': { display: 'none' },
              }}
            >
              {orderTypeFilters.map((orderType) => (
                <CustomButton
                  key={orderType.id}
                  variant="outlined"
                  color={orderTypes.includes(orderType.id) ? 'primary' : 'info'}
                  sx={{ px: '12px' }}
                  onClick={() => handleChangeOrderType(orderType.id)}
                >
                  <Typography
                    noWrap
                    sx={{ fontSize: '12px', textTransform: 'capitalize' }}
                  >
                    {orderType.name}
                  </Typography>
                </CustomButton>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <CustomButton
            fullWidth
            variant="contained"
            onClick={handleSubmitFilters}
          >
            Apply
          </CustomButton>
        </DialogActions>
      </CustomBottomDrawer>
      <CustomBottomDrawer isOpen={isOpenDate} setIsOpen={setIsOpenDate}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>Date Range</Typography>
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => setIsOpenDate(!isOpenDate)}
          />
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', gap: 1 }}>
          <MobileDatePicker
            label="Start"
            value={startDate}
            inputFormat="DD/MMM/YY"
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
          <MobileDatePicker
            label="Until"
            value={endDate}
            inputFormat="DD/MMM/YY"
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton
            fullWidth
            variant="contained"
            onClick={handleSubmitDate}
          >
            Submit
          </CustomButton>
        </DialogActions>
      </CustomBottomDrawer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          overflowX: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
        }}
      >
        <CustomButton
          variant="outlined"
          color="info"
          sx={{
            px: '12px',
            '& .MuiSvgIcon-root': { fontSize: '16px' },
            gap: 0.5,
          }}
          onClick={() => setIsOpenFilter(!isOpenFilter)}
        >
          <FilterListIcon />
          <Typography sx={{ fontSize: '12px' }}>Filter</Typography>
        </CustomButton>
        <CustomButton
          startIcon={<CalendarIcon />}
          endIcon={<ArrowDownIcon />}
          variant="outlined"
          color="primary"
          sx={{ px: '12px', minWidth: '210px' }}
          onClick={() => setIsOpenDate(!isOpenDate)}
        >
          <Typography noWrap sx={{ fontSize: '12px' }}>
            {dayjs(savedStartDate).format('DD/MMM/YY')} -{' '}
            {dayjs(savedEndDate).format('DD/MMM/YY')}
          </Typography>
        </CustomButton>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {statusFilters.map((filter) => (
            <CustomButton
              key={filter.id}
              variant="outlined"
              color={ordersStatus.includes(filter.id) ? 'primary' : 'info'}
              sx={{ px: '12px' }}
              onClick={() => handleChangeStatus(filter.id)}
            >
              <Typography noWrap sx={{ fontSize: '12px' }}>
                {filter.name}
              </Typography>
            </CustomButton>
          ))}
        </Box>
      </Box>
    </>
  );
};
export default OrderFilters;
