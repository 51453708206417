import { styled } from '@mui/material/styles';
import { days, invoiceStatus, operatingHourStatus } from './enum';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
  gap: 30,
}));

export const responsiveFontSize = (value) => (value / 390) * 100;

export const maxFontSize = (value) => (responsiveFontSize(value) / 100) * 444;

export const numberWithCommas = (value) => {
  value = value.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(value)) value = value.replace(pattern, '$1,$2');
  return value;
};

export const dayName = (value) => {
  switch (value) {
    case days.sunday:
      return 'Sunday';
    case days.monday:
      return 'Monday';
    case days.tuesday:
      return 'Tuesday';
    case days.wednesday:
      return 'Wednesday';
    case days.thursday:
      return 'Thursday';
    case days.friday:
      return 'Friday';
    case days.saturday:
      return 'Saturday';
    default:
      return 'Day';
  }
};

export const operatingHourStatusBoolean = (value) => {
  switch (value) {
    case operatingHourStatus.open:
      return true;
    case operatingHourStatus.closed:
      return false;
    default:
      return false;
  }
};

export const operatingHourStatusString = (value) => {
  switch (value) {
    case true:
      return operatingHourStatus.open;
    case false:
      return operatingHourStatus.closed;
    default:
      return '';
  }
};

export const invoiceStatusLabel = (value) => {
  switch (value) {
    case invoiceStatus.pending:
      return { name: 'Pending', color: '#EB8D00', bgcolor: '#FFAF3633' };
    case invoiceStatus.paid:
      return { name: 'Paid', color: '#1A72DD', bgcolor: '#1A72DD33' };
    case invoiceStatus.finished:
      return { name: 'Finished', color: '#4FD240', bgcolor: '#4FD24033' };
    case (invoiceStatus.customer_cancelled, invoiceStatus.admin_cancelled):
      return { name: 'Canceled', color: '#EB0000', bgcolor: '#FF363633' };
    case invoiceStatus.expired:
      return { name: 'Expired', color: '#EB0000', bgcolor: '#FF363633' };
    case invoiceStatus.failed:
      return { name: 'Failed', color: '#EB0000', bgcolor: '#FF363633' };
    default:
      return { name: 'Status', color: '#000', bgcolor: '#fff' };
  }
};
