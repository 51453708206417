import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Add as AddIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DrawerHeader } from '../../helpers/general';
import CustomDrawer from '../../components/shared/CustomDrawer';
import { ReactComponent as NoArea } from '../../assets/icons/noarea.svg';
import CustomFab from '../../components/shared/CustomFab';
import CustomDialog from '../../components/shared/CustomDialog';
import CustomInput from '../../components/shared/CustomInput';
import { getArea } from '../../api/table';
import CustomCard from '../../components/shared/CustomCard';
import CustomAntSwitch from '../../components/shared/CustomAntSwitch';
import CustomButton from '../../components/shared/CustomButton';
import { useDispatch } from 'react-redux';
import {
  changeColumn,
  changeIdArea,
  changeName,
  changeRow,
} from '../../store/area/detailArea';
import { addArea, updateArea } from '../../api/Area';

const AreaMenu = ({ permissions }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [dataArea, setDataArea] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //! name
  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);
  //! Column
  const [column, setColumn] = useState('');
  const [errorColumn, setErrorColumn] = useState(false);
  //! Row
  const [row, setRow] = useState('');
  const [errorRow, setErrorRow] = useState(false);

  //! fetch area
  const fetchArea = async () => {
    setIsLoading(true);
    try {
      const data = await getArea();
      setDataArea(data.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchArea();
  }, []);

  //! validasi submit
  const [isAreaSubmit, setIsAreaSubmit] = useState(false);
  useEffect(() => {
    if (name !== '' && column !== '' && row !== '') {
      setIsAreaSubmit(true);
    } else {
      setIsAreaSubmit(false);
    }
  }, [name, column, row]);

  //! handle submit
  const handleSubmit = () => {
    setIsAreaSubmit(true);
    let datas = {
      name: name,
      column: column,
      row: row,
    };

    const addNewArea = async () => {
      try {
        await addArea(datas);
        setIsAreaSubmit(false);
        setIsOpenAdd(false);
      } catch (err) {
        console.error();
      } finally {
        fetchArea();
        setIsAreaSubmit(false);
      }
    };

    addNewArea();
  };

  //! update status area
  const handleUpdateStatusArea = (e, id, status) => {
    e.stopPropagation();

    let data = {
      status: status ? 0 : 1,
    };

    const updatearea = async () => {
      try {
        const result = await updateArea(id, data);
      } catch (err) {
        console.error(err);
      } finally {
        fetchArea();
      }
    };
    updatearea();
  };

  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ color: '#000' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexGrow: 1,
              ml: 1,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              Area
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <DrawerHeader />
      <CustomDrawer isOpen={isOpen} setIsOpen={setIsOpen} />

      {/* main */}
      <Container maxWidth="lg" disableGutters>
        {isLoading ? (
          <Box
            sx={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ p: 2 }}>
            {dataArea.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexFlow: 'column',
                  alignItems: 'center',
                  height: '50vh',
                }}
              >
                <NoArea />
                <Typography sx={{ fontSize: '14px', color: '#9E9D9D' }}>
                  You haven't added areas
                </Typography>
                <Typography sx={{ fontSize: '14px', color: '#9E9D9D' }}>
                  Click + icon to add new.
                </Typography>
              </Box>
            ) : (
              <Box>
                {dataArea.map((item, index) => (
                  <CustomCard
                    onClick={() => {
                      dispatch(changeIdArea(item?.id));
                      dispatch(changeName(item?.name));
                      dispatch(changeColumn(item?.column));
                      dispatch(changeRow(item?.row));
                      navigate(`detail/${item?.id}`);
                    }}
                    sx={{ background: '#FAFAFA', mt: 3, mb: 5 }}
                    key={index}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontWeight: 600,
                            color: '#000000',
                          }}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          sx={{ fontSize: '12px', color: '#9E9D9D' }}
                        >
                          {item?.tableCount} tables
                        </Typography>
                      </Box>
                      <Box>
                        <CustomAntSwitch
                          checked={item?.status === 0 ? false : true}
                          onClick={(e) => {
                            handleUpdateStatusArea(e, item?.id, item?.status);
                          }}
                        />
                      </Box>
                    </Box>
                  </CustomCard>
                ))}
              </Box>
            )}

            {/* button add area */}
            {permissions.includes('add-product') && (
              <CustomFab
                sx={{
                  position: 'fixed',
                  bottom: 25,
                  right: 25,
                }}
                color="primary"
                icon={<AddIcon />}
                onClick={() => {
                  navigate('addArea');
                }}
              />
            )}
          </Box>
        )}
      </Container>
      <CustomDialog isOpen={isOpenAdd} setIsOpen={setIsOpenAdd}>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              Add Area
            </Typography>
            <Box sx={{ cursor: 'pointer' }} onClick={() => setIsOpenAdd(false)}>
              <CloseIcon />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container columns={12} spacing={3}>
            {/* name */}
            <Grid item xs={12}>
              <CustomInput
                label="Area Name*"
                readOnly={!permissions.includes('add-outlet-area')}
                fullWidth
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                  setErrorName(false);
                }}
                sx={{
                  '& .MuiOutlinedInput-input': {
                    paddingInline: '14px',
                    fontSize: '12px',
                  },
                  mt: 1,
                }}
                error={errorName}
                helperText={errorName && 'required'}
              />
            </Grid>

            {/* col */}
            <Grid item xs={6}>
              <CustomInput
                label="Column"
                type="number"
                readOnly={!permissions.includes('add-outlet-area')}
                fullWidth
                value={column}
                onChange={(event) => {
                  setColumn(event.target.value);
                  setErrorColumn(false);
                }}
                sx={{
                  '& .MuiOutlinedInput-input': {
                    paddingInline: '14px',
                    fontSize: '12px',
                  },
                  mt: 1,
                }}
                error={errorColumn}
                helperText={errorColumn && 'required'}
              />
            </Grid>

            {/* row */}
            <Grid item xs={6}>
              <CustomInput
                label="Row"
                type="number"
                readOnly={!permissions.includes('add-outlet-area')}
                fullWidth
                value={row}
                onChange={(event) => {
                  setRow(event.target.value);
                  setErrorRow(false);
                }}
                sx={{
                  '& .MuiOutlinedInput-input': {
                    paddingInline: '14px',
                    fontSize: '12px',
                  },
                  mt: 1,
                }}
                error={errorRow}
                helperText={errorRow && 'required'}
              />
            </Grid>

            {/* button submit */}
            <Grid item xs={12}>
              <CustomButton
                onClick={handleSubmit}
                disabled={!isAreaSubmit}
                type="submit"
                variant="contained"
                fullWidth
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    color: '#FFFFFF',
                  }}
                >
                  Submit
                </Typography>
              </CustomButton>
            </Grid>
          </Grid>
        </DialogContent>
      </CustomDialog>
    </Box>
  );
};
export default AreaMenu;
