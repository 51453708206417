import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../components/shared/CustomAppBarBack';
import ProductCategoryEditForms from '../components/ProductCategoryEditForms';

const ProductCategoryEdit = ({ matches, permissions }) => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title='Edit Category' />
      <Container maxWidth='lg' disableGutters>
        <ProductCategoryEditForms matches={matches} permissions={permissions} />
      </Container>
    </Box>
  );
};
export default ProductCategoryEdit;
