import { Box, Container } from '@mui/material';
import CustomAppBarBack from '../../../components/shared/CustomAppBarBack';
import TableAddSubCategoryForms from '../../../components/TableAddSubCategoryForms';

const AddSubCategoryMenu = ({ matches, permissions }) => {
  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarBack title="Add Sub Category" />
      <Container maxWidth="lg" disableGutters>
        <TableAddSubCategoryForms matches={matches} permissions={permissions} />
      </Container>
    </Box>
  );
};
export default AddSubCategoryMenu;
