import { createSlice } from '@reduxjs/toolkit';

export const addTableState = createSlice({
  name: 'addTable',
  initialState: {
    cellChoose: null,
  },
  reducers: {
    changeCellChoose: (state, action) => {
      state.cellChoose = action.payload;
    },
  },
});

export const { changeCellChoose } = addTableState.actions;

export const getCellChoose = (state) => state.addTable.cellChoose;

export default addTableState.reducer;
