import axios from 'axios';
import { adminAxios } from '../helpers/api';

export const getProductCategories = ({ keyword, page, pageSize = 10 }) =>
  adminAxios.get('product-categories', {
    params: { keyword: keyword || null, page, pageSize },
  });

export const getProductcategoryData = (params) =>
  adminAxios.get('product-categories', { params: params });

export const postProductCategory = (data) =>
  adminAxios.post('product-categories', data);

export const patchProductCategoryStatus = ({ id, status }) =>
  adminAxios.patch(`product-categories/${id}/set-status`, { status });

export const getProductCategoryById = ({ id }) =>
  adminAxios.get(`product-categories/${id}`);

export const getSpesifikProductCategoryById = (id) =>
  adminAxios.get(`product-categories/${id}`);

export const patchProductCategoryById = ({ id, data }) =>
  adminAxios.patch(`product-categories/${id}`, data);

export const postProductCategoryImage = (data) =>
  adminAxios.post(`product-categories/images`, data);

export const getCategoryByStatus = () => adminAxios.get('product/category');
 
//! ----------------- Sub Category -------------------------------
export const getSubCategory = (params) =>
  adminAxios.get('product/sub-category', { params: params });

export const uploadImage = (data) => {
  const accessToken = localStorage.getItem('accessToken');

  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_BASE_URL}/product/sub-category/image`,
    data: data,
    headers: {
      Access: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const uploadSubCategory = (data) =>
  adminAxios.post('product/sub-category', data);

export const updateStatusSubCategory = (id) =>
  adminAxios.put(`product/sub-category/${id}`);

export const getDetailSubCategory = (id) =>
  adminAxios.get(`product/sub-category/${id}`);

export const updateSubCategory = (id, params) =>
  adminAxios.put(`product/sub-category/${id}/update`, params);

export const deleteSubCategory = (id) =>
  adminAxios.delete(`product/sub-category/${id}`);

export const getSubCategoryByStatus = (params) =>
  adminAxios.get('product/subcategory', { params: params });
