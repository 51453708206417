import {
  Search as SearchIcon,
  Add as AddIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSearchParams, createSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import CustomInput from '../components/shared/CustomInput';
import CustomButton from '../components/shared/CustomButton';
import CustomDialog from '../components/shared/CustomDialog';
import CustomAppBarFilter from '../components/shared/CustomAppBarFilter';
import CustomFab from '../components/shared/CustomFab';
import StationCards from '../components/StationCards';
import { getStations, postStation } from '../api/station';

const Station = ({ permissions }) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const updatedSearchParams = createSearchParams(searchParams);

  const [keyword, setKeyword] = useState(searchParams.get('keyword') || '');
  const [savedKeyword, setSavedKeyword] = useState(
    searchParams.get('keyword') || ''
  );
  const debounceKeyword = useRef(
    debounce((nextValue) => {
      setSavedKeyword(nextValue);
    }, 1000)
  ).current;

  const handleChangeKeyword = (event) => {
    const { value } = event.target;
    value !== ''
      ? updatedSearchParams.set('keyword', value)
      : updatedSearchParams.delete('keyword');
    setSearchParams(updatedSearchParams, { replace: true });
    setKeyword(value);
    debounceKeyword(value);
  };

  const handleClearKeyword = () => {
    updatedSearchParams.delete('keyword');
    setSearchParams(updatedSearchParams, { replace: true });
    setKeyword('');
    debounceKeyword('');
  };

  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [stationName, setStationName] = useState('');
  const [errorStationName, setErrorStationName] = useState(false);

  const [hasMore, setHasMore] = useState(false);

  const fetchStations = async ({ keyword }) => {
    try {
      setIsLoading(true);

      const {
        data: { stations, nextPageUrl },
      } = await getStations({ keyword });
      setStations(stations, nextPageUrl);
      if (nextPageUrl) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setErrorStationName(false);
    setStationName('');
  }, [isOpenAdd]);

  const handleAddStation = async (event) => {
    event.preventDefault();

    if (stationName !== '') {
      try {
        setIsLoading(true);
        await postStation({ name: stationName });
        await fetchStations({ keyword: savedKeyword });
        setIsOpenAdd(!isOpenAdd);
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorStationName(true);
    }
  };

  const [stations, setStations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchStations({ keyword: savedKeyword });
  }, [savedKeyword]);

  const SearchField = (
    <CustomInput
      placeholder='Station'
      fullWidth
      value={keyword}
      onChange={handleChangeKeyword}
      startAdornment={<SearchIcon />}
      endAdornment={
        keyword !== '' && (
          <CloseIcon onClick={handleClearKeyword} sx={{ cursor: 'pointer' }} />
        )
      }
      sx={{
        '& .MuiOutlinedInput-input': { paddingBlock: '8px' },
      }}
    />
  );

  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <CustomAppBarFilter title='Station' filters={[SearchField]} />
      <Container maxWidth='lg' disableGutters>
        <Box sx={{ p: 2 }}>
          <CustomDialog
            isOpen={isOpenAdd}
            onClose={() => setIsOpenAdd(!isOpenAdd)}
          >
            <Box component='form' onSubmit={handleAddStation}>
              <DialogTitle
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 1,
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>Add Station</Typography>
                <CloseIcon
                  sx={{ fontSize: '1.25rem' }}
                  onClick={() => {
                    setIsOpenAdd(!isOpenAdd);
                  }}
                />
              </DialogTitle>
              <DialogContent>
                <CustomInput
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      paddingInline: '14px',
                      fontSize: '0.875rem',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      boxShadow:
                        errorStationName &&
                        `0 0 4px 0 ${theme.palette.error.main}`,
                    },
                    '&.Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        boxShadow:
                          errorStationName &&
                          `0 0 4px 0 ${theme.palette.error.main}`,
                      },
                    },
                  }}
                  fullWidth
                  label='Name'
                  placeholder='Name'
                  value={stationName}
                  onChange={(event) => {
                    setStationName(event.target.value);
                    setErrorStationName(false);
                  }}
                  error={errorStationName}
                  helperText={errorStationName && 'required'}
                />
              </DialogContent>
              <DialogActions>
                <CustomButton
                  disabled={isLoading}
                  type='submit'
                  variant='contained'
                >
                  Submit
                </CustomButton>
              </DialogActions>
            </Box>
          </CustomDialog>

          <Box>
            {isLoading && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '500px',
                  transform: 'scaleX(-1)',
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {!isLoading && stations.length === 0 && (
              <Typography sx={{ fontSize: '0.875rem' }}>
                No stations found
              </Typography>
            )}

            {!isLoading && stations.length > 0 && (
              <StationCards
                stations={stations}
                fetchStations={fetchStations}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                keyword={savedKeyword}
                hasMore={hasMore}
                setStations={setStations}
                setHasMore={setHasMore}
                permissions={permissions}
              />
            )}
          </Box>
        </Box>
        {permissions.includes('add-station') && (
          <CustomFab
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            color='primary'
            icon={<AddIcon />}
            onClick={() => setIsOpenAdd(!isOpenAdd)}
          />
        )}
      </Container>
    </Box>
  );
};
export default Station;
