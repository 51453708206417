import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { Close as CloseIcon } from '@mui/icons-material';
import { DrawerHeader } from '../../../helpers/general';
import CustomDrawer from '../../../components/shared/CustomDrawer';
import { useEffect, useRef, useState } from 'react';
import { Menu as MenuIcon, Add as AddIcon } from '@mui/icons-material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getTable } from '../../../api/table';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import {
  changeIdexGrid,
  getColumn,
  getIndexGrid,
  getName,
  getRow,
  getidArea,
} from '../../../store/area/detailArea';
import CustomFab from '../../../components/shared/CustomFab';
import { changeCellChoose } from '../../../store/table/addTable';
import CustomCard from '../../../components/shared/CustomCard';
import CustomButton from '../../../components/shared/CustomButton';
import CustomDialog from '../../../components/shared/CustomDialog';
import { ReactComponent as Checklist } from '../../../assets/icons/checklist.svg';
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg';
import CustomInput from '../../../components/shared/CustomInput';
import { deleteArea, getDetailArea, updateArea } from '../../../api/Area';

const DetailAreaMenu = ({ matches, permissions }) => {
  const { idArea } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [nameArea, setNameArea] = useState();
  const [column, setColumn] = useState();
  const [row, setRow] = useState();
  const [cellChoose, setCellChoose] = useState(null);
  const indexGrids = useSelector(getIndexGrid);
  const [isLoading, setIsLoading] = useState(false);

  const [dataListTable, setDataListTable] = useState();

  //! name
  const [name, setName] = useState(nameArea);
  const [errorName, setErrorName] = useState(false);
  //! Column
  const [newColumn, setNewColumn] = useState(column);
  const [errorColumn, setErrorColumn] = useState(false);
  //! Row
  const [newRow, setNewRow] = useState(row);
  const [errorRow, setErrorRow] = useState(false);

  // ! get spesifik area
  const fetchSpesifikArea = async () => {
    setIsLoading(true);
    try {
      const data = await getDetailArea(idArea);
      setNameArea(data?.data?.name);
      setColumn(data.data.column);
      setRow(data.data.row);
      setName(data?.data?.name);
      setNewColumn(data.data.column);
      setNewRow(data.data.row);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchListTable = async () => {
    try {
      const { data } = await getTable(idArea);
      setDataListTable(data);
    } catch (err) {
      console.error();
    }
  };
  useEffect(() => {
    fetchListTable();
    fetchSpesifikArea();
  }, [idArea]);

  // useEffect(() => {
  //   const indexOfGrid = dataListTable.map((item) => item.indexGrid);
  //   dispatch(changeIdexGrid(indexOfGrid));
  // }, [dataListTable]);

  //! handle button
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  //! validation
  // useEffect(() => {
  //   if (dataListTable.length !== 0) {
  //     setIsDisabled(false);
  //   } else {
  //     setIsDisabled(true);
  //   }
  // }, [dataListTable]);

  //! handle submit
  const [disableSubmitEdit, setDisableSubmitEdit] = useState(false);
  const handleSubmit = () => {
    setDisableSubmitEdit(true);
    let datas = {
      name: name,
      column: newColumn,
      row: newRow,
    };

    const addNewArea = async () => {
      try {
        await updateArea(idArea, datas);
        setDisableSubmitEdit(false);
        setIsEditDialogOpen(false);
      } catch (err) {
        console.error();
      } finally {
        fetchSpesifikArea();
        setDisableSubmitEdit(false);
      }
    };

    addNewArea();
  };

  // !delete

  const [disableDelete, setDisableDelete] = useState(false);
  const handleDelete = () => {
    const deletearea = async () => {
      setDisableDelete(true);
      try {
        await deleteArea(idArea);
      } catch (err) {
        console.error(err);
      } finally {
        setDisableDelete(false);
        navigate(-1);
      }
    };
    deletearea();
  };

  return (
    <Box sx={{ minHeight: '100vh', width: '100vw' }}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{ color: '#000' }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexGrow: 1,
              ml: 1,
            }}
          >
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
              {nameArea}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
      <DrawerHeader />
      <CustomDrawer isOpen={isOpen} setIsOpen={setIsOpen} />

      {/* main */}
      <Container maxWidth="lg" disableGutters sx={{ paddingBottom: '50px' }}>
        <Box sx={{ p: 2 }}>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '30vh',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid container columns={12} spacing={3}>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: '14px ', fontWeight: 600, ml: '16px' }}
                >
                  <IconButton
                    edge="start"
                    sx={{
                      background: '#4FD240',
                      width: '16px',
                      padding: '10px',
                      height: '16px',
                      mr: '16px',
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBackIosNewIcon
                      sx={{ width: '15px', color: '#FFFFFF' }}
                    />
                  </IconButton>
                  Table Mapping
                </Typography>
              </Grid>

              {/* grid table */}
              {/* <Grid item xs={12}>
                <ScrollMenu>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            paddingLeft: '26px',
                          }}
                        >
                          {[...Array(column)].map((_, index) => (
                            <Box
                              sx={{
                                width:
                                  column < 12 ? `${312 / column}px` : '26px',
                                height: '26px',
                                lineHeight: '26px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {String.fromCharCode(index + 65)}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Box>
                          {[...Array(row)].map((_, index) => (
                            <Box
                              sx={{
                                width: '26px',
                                height: row < 12 ? `${312 / row}px` : '26px',
                                lineHeight:
                                  row < 12 ? `${312 / row}px` : '26px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {index + 1}
                            </Box>
                          ))}
                        </Box>
                        <Box>
                          {[...Array(row)].map((_, rowIndex) => {
                            return (
                              <Box sx={{ display: 'flex' }}>
                                {[...Array(column)].map((_, colIndex) => {
                                  const indexCell =
                                    colIndex + rowIndex * column + 1;

                                  return (
                                    <Box
                                      sx={[
                                        {
                                          width:
                                            column < 12
                                              ? `${312 / column}px`
                                              : '26px',
                                          height:
                                            row < 12
                                              ? `${312 / row}px`
                                              : '26px',
                                          lineHeight:
                                            row < 12
                                              ? `${312 / row}px`
                                              : '26px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          background: '#E4E4E4',
                                          border: '1px solid #000000',
                                        },
                                        indexGrids.includes(indexCell) && {
                                          bgcolor: '#4FD240',
                                        },
                                      ]}
                                    ></Box>
                                  );
                                })}
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ScrollMenu>
              </Grid> */}

              <Grid item xs={12}>
                <Carousel autoPlay={false}>
                  {dataListTable?.images.length !== 0 ? (
                    dataListTable?.images.map((image, index) => {
                      return (
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '12px',
                            backgroundImage: `url(${image.imageUrl})`,
                            aspectRatio: '2/1',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            position: 'relative',
                          }}
                        >
                          {dataListTable?.images[index]?.outletTable.map(
                            (outletTables, index) => (
                              <Stack direction={row}>
                                <Paper
                                  sx={{
                                    background: '#FAFAFA',
                                    padding: '4px',
                                    border: 'none',
                                    minWidth: '41px',
                                    boxShadow: 'none',
                                    position: 'absolute',
                                    textAlign: 'center',
                                    left:
                                      outletTables?.xPosition >= 85
                                        ? '80%'
                                        : `${outletTables?.xPosition}%`,
                                    top:
                                      outletTables?.yPosition >= 85
                                        ? '85%'
                                        : `${outletTables?.yPosition}%`,
                                  }}
                                >
                                  <Typography
                                    sx={{ fontSize: '8px', fontWeight: 600 }}
                                  >
                                    {outletTables?.name}
                                  </Typography>
                                </Paper>
                              </Stack>
                            )
                          )}
                        </Box>
                      );
                    })
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '12px',
                        backgroundImage: `url("https://dev-enak.x5.com.au/storage/products/08318b61295c4f3b8b09ea6be0494280.jpg")`,
                        aspectRatio: '2/1',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        position: 'relative',
                      }}
                    ></Box>
                  )}
                </Carousel>
              </Grid>

              {/* list table */}
              <Grid item xs={12}>
                <CustomCard>
                  <Box
                    sx={{
                      mb: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#000000',
                      }}
                    >
                      Table List
                    </Typography>
                    {/* button add table */}
                    {/* {permissions.includes('add-outlet-table') && ( */}
                    <CustomButton
                      sx={{ height: '27px' }}
                      component={Link}
                      to={`/area/${idArea}/addTable`}
                      onClick={() => dispatch(changeCellChoose(cellChoose))}
                      variant="contained"
                      color="primary"
                    >
                      <AddIcon sx={{ width: '10px', mr: 1 }} />
                      <Typography sx={{ fontSize: '10px', fontWeight: 600 }}>
                        Add Table
                      </Typography>
                    </CustomButton>

                    {/* )} */}
                  </Box>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow
                        sx={{ background: '#F3F3F3', fontSize: '30px' }}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: '10px',
                            fontWeight: 600,
                            color: '#000000',
                          }}
                        >
                          No
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: '10px',
                            fontWeight: 600,
                            color: '#000000',
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: '10px',
                            fontWeight: 600,
                            color: '#000000',
                          }}
                        >
                          Capacity
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataListTable?.table.length !== 0 ? (
                        dataListTable?.table.map((item, index) => (
                          <TableRow
                            key={index}
                            component={Link}
                            to={`/area/${idArea}/editTable/${item?.id}`}
                            sx={{ textDecoration: 'none' }}
                          >
                            <TableCell
                              align="left"
                              sx={{
                                fontSize: '10px',
                                fontWeight: 400,
                                color: '#000000',
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontSize: '10px',
                                fontWeight: 500,
                                color: '#4FD240',
                              }}
                            >
                              {item?.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                fontSize: '10px',
                                fontWeight: 400,
                                color: '#000000',
                              }}
                            >
                              {item?.capacity}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#9E9D9D',
                                width: '100%',
                              }}
                            >
                              You haven't added any table yet
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </CustomCard>
              </Grid>
            </Grid>
          )}
        </Box>

        <Box
          sx={{
            width: '100%',
            height: '37px',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            mb: '4px',
          }}
        >
          <Container>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                // background: '#999',
                borderRadius: '4px',
              }}
            >
              {/* button delete */}
              <CustomButton
                onClick={() => setIsDeleteDialogOpen(true)}
                type="submit"
                disabled={!isDisabled}
                fullWidth
                variant="contained"
                color="error"
              >
                <Typography sx={{ color: '#FFFFFF' }}>Delete</Typography>
              </CustomButton>

              {/* button edit */}
              <CustomButton
                component={Link}
                to={`/area/editArea/${idArea}`}
                type="submit"
                fullWidth
                variant="contained"
              >
                Edit
              </CustomButton>
            </Box>
          </Container>
        </Box>

        {/* dialog edit */}
        <CustomDialog isOpen={isEditDialogOpen} setIsOpen={setIsEditDialogOpen}>
          <DialogTitle>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                Edit Area
              </Typography>
              <Box
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => setIsEditDialogOpen(false)}
              >
                <CloseIcon />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container columns={12} spacing={3}>
              {/* name */}
              <Grid item xs={12}>
                <CustomInput
                  label="Area Name*"
                  readOnly={!permissions.includes('add-outlet-area')}
                  fullWidth
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                    setErrorName(false);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      paddingInline: '14px',
                      fontSize: '12px',
                    },
                    mt: 1,
                  }}
                  error={errorName}
                  helperText={errorName && 'required'}
                />
              </Grid>

              {/* col */}
              <Grid item xs={6}>
                <CustomInput
                  label="Column"
                  type="number"
                  readOnly={!permissions.includes('add-outlet-area')}
                  fullWidth
                  value={newColumn}
                  onChange={(event) => {
                    setNewColumn(event.target.value);
                    setErrorColumn(false);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      paddingInline: '14px',
                      fontSize: '12px',
                    },
                    mt: 1,
                  }}
                  error={errorColumn}
                  helperText={errorColumn && 'required'}
                />
              </Grid>

              {/* row */}
              <Grid item xs={6}>
                <CustomInput
                  label="Row"
                  type="number"
                  readOnly={!permissions.includes('add-outlet-area')}
                  fullWidth
                  value={newRow}
                  onChange={(event) => {
                    setNewRow(event.target.value);
                    setErrorRow(false);
                  }}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      paddingInline: '14px',
                      fontSize: '12px',
                    },
                    mt: 1,
                  }}
                  error={errorRow}
                  helperText={errorRow && 'required'}
                />
              </Grid>

              {/* button submit */}
              <Grid item xs={12}>
                <CustomButton
                  disabled={disableSubmitEdit}
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 600,
                      color: '#FFFFFF',
                    }}
                  >
                    Submit
                  </Typography>
                </CustomButton>
              </Grid>
            </Grid>
          </DialogContent>
        </CustomDialog>

        {/* custom Detele */}
        <CustomDialog
          isOpen={isDeleteDialogOpen}
          setIsOpen={setIsDeleteDialogOpen}
        >
          <DialogTitle>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                padding: '30px 0px 0px 35px',
              }}
            >
              <Box
                sx={{
                  width: '43px',
                  height: '43px',
                  background: ' rgba(243, 58, 58, 0.1)',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Trash />
              </Box>
              <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>
                Delete Table
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ padding: '10px 0px 50px 35px' }}>
              <Typography sx={{ fontSize: '14px' }}>
                Are you sure you want to delete changes?
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                padding: '30px 55px 0px 35px',
              }}
            >
              <Box
                onClick={() => setIsDeleteDialogOpen(false)}
                sx={{ width: '100px', cursor: 'pointer' }}
              >
                <Typography
                  sx={{ fontSize: '14px', color: '#9E9D9D', fontWeight: 600 }}
                >
                  Back
                </Typography>
              </Box>

              <CustomButton
                disabled={disableDelete}
                onClick={handleDelete}
                type="submit"
                variant="contained"
                color="error"
                sx={{ width: '100px' }}
              >
                Delete
              </CustomButton>
            </Box>
          </DialogContent>
        </CustomDialog>
      </Container>
    </Box>
  );
};
export default DetailAreaMenu;
