import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import dayjs from 'dayjs';
import { invoiceStatusLabel } from '../helpers/general';

const OrderDetailData = ({ orderDetails }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
        <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
          Invoice ID
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            wordBreak: 'break-all',
          }}
        >
          {orderDetails?.id}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
        <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
          Order Type
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            textTransform: 'capitalize',
          }}
        >
          {orderDetails?.orderType?.name}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
        <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
          Payment Method
        </Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
          {orderDetails?.paymentMethod?.name}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
        <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
          Transaction Time
        </Typography>
        <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
          {dayjs(orderDetails?.createdAt).format('DD/MMM/YY')},{' '}
          {dayjs(orderDetails?.createdAt).format('HH:MM')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
        <Typography sx={{ fontSize: '12px', fontWeight: 400 }}>
          Transaction Status
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 600,
            color: invoiceStatusLabel(orderDetails?.status).color,
          }}
        >
          {invoiceStatusLabel(orderDetails?.status).name}
        </Typography>
      </Box>
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
};

export default OrderDetailData;
