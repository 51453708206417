import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteBannerById } from '../api/banner';
import CustomCard from '../components/shared/CustomCard';
import CustomDialog from './shared/CustomDialog';

const BannerCards = ({ banners, setBanners, permissions }) => {
  const navigate = useNavigate();

  const ActionButton = ({ color, onClick, children }) => (
    <Box
      sx={{
        width: '28px',
        height: '28px',
        borderRadius: '4px',
        color: color,
        bgcolor: `${color}33`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );

  const [selectedBanner, setSelectedBanner] = useState(null);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isOpenDelete) setSelectedBanner(null);
  }, [isOpenDelete]);

  const handleDelete = async (banner) => {
    setIsLoading(true);

    let tempBanners = [...banners];
    tempBanners = tempBanners.filter(
      (tempBanner) => tempBanner.id !== banner.id
    );

    await deleteBannerById({ id: banner.id })
      .then(() => {
        setBanners(tempBanners);
        setIsOpenDelete(!isOpenDelete);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {banners.map((banner, index) => (
        <CustomCard key={banner.id} sx={{ p: 0, overflow: 'hidden' }}>
          <Box
            component='img'
            src={banner.imageUrl}
            sx={{ width: 1, aspectRatio: '7 / 4' }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1.5,
              p: 1.5,
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                wordBreak: 'break-word',
              }}
            >
              {banner.name}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1.5 }}>
              {permissions.includes('edit-outlet-banner') && (
                <ActionButton
                  color='#FD7900'
                  onClick={() => navigate(`${banner.id}/edit`)}
                >
                  <EditIcon sx={{ fontSize: '22px' }} />
                </ActionButton>
              )}
              {permissions.includes('delete-outlet-banner') && (
                <ActionButton
                  color='#F33A3A'
                  onClick={() => {
                    setSelectedBanner(banner);
                    setIsOpenDelete(!isOpenDelete);
                  }}
                >
                  <DeleteIcon sx={{ fontSize: '22px' }} />
                </ActionButton>
              )}
            </Box>
          </Box>
        </CustomCard>
      ))}
      <CustomDialog
        isOpen={isOpenDelete}
        onClose={() => setIsOpenDelete(!isOpenDelete)}
      >
        <DialogTitle fontWeight={700} align='center'>
          Delete Banner?
        </DialogTitle>
        <DialogContent>
          <Typography align='center' sx={{ fontWeight: 500 }}>
            Are you sure to delete "{selectedBanner?.name}" banner?
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', gap: 1, alignItems: 'center', pt: 1 }}
        >
          <Box
            sx={{
              width: 1,
              bgcolor: 'rgba(0,0,0,0.25)',
              color: '#fff',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 600,
              py: 1,
              cursor: 'pointer',
            }}
            onClick={() => setIsOpenDelete(!isOpenDelete)}
          >
            Cancel
          </Box>
          <Box
            sx={{
              width: 1,
              bgcolor: '#F33A3A40',
              color: '#F33A3A',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 600,
              py: 1,
              cursor: 'pointer',
            }}
            onClick={() => !isLoading && handleDelete(selectedBanner)}
          >
            Delete
          </Box>
        </DialogActions>
      </CustomDialog>
    </Box>
  );
};
export default BannerCards;
