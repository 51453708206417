import { Box, Switch, Typography } from '@mui/material';
import CustomCard from '../components/shared/CustomCard';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@mui/icons-material';
import { patchPaymentMethodStatus } from '../api/paymentMethod';

const PaymentMethodCards = ({
  paymentMethodGroups,
  setPaymentMethodGroups,
  permissions,
}) => {
  const handleExpanded = (paymentMethodGroup) => {
    let tempPaymentMethodGroups = [...paymentMethodGroups];
    let tempPaymentMethodGroup = tempPaymentMethodGroups.find(
      (tempPaymentMethodGroup) =>
        tempPaymentMethodGroup.id === paymentMethodGroup.id
    );
    tempPaymentMethodGroup.isExpanded = !tempPaymentMethodGroup.isExpanded;

    setPaymentMethodGroups(tempPaymentMethodGroups);
  };

  const handleChangeStatus = async (paymentMethod, paymentMethodGroup) => {
    let tempPaymentMethodGroups = [...paymentMethodGroups];
    let tempPaymentMethodGroup = tempPaymentMethodGroups.find(
      (tempPaymentMethodGroup) =>
        tempPaymentMethodGroup.id === paymentMethodGroup.id
    );
    let tempPaymentMethods = tempPaymentMethodGroup.paymentMethods;
    let tempPaymentMethod = tempPaymentMethods.find(
      (tempPaymentMethod) => tempPaymentMethod.id === paymentMethod.id
    );

    tempPaymentMethod.outletPaymentMethod.status =
      +!tempPaymentMethod.outletPaymentMethod.status;

    setPaymentMethodGroups(tempPaymentMethodGroups);

    await patchPaymentMethodStatus({
      id: tempPaymentMethod.outletPaymentMethod?.id,
      data: { status: tempPaymentMethod.outletPaymentMethod.status },
    });
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {paymentMethodGroups.map((paymentMethodGroup, index) => (
          <CustomCard key={index}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
                cursor: 'pointer',
              }}
              onClick={() => handleExpanded(paymentMethodGroup)}
            >
              <Typography sx={{ fontWeight: 500 }}>
                {paymentMethodGroup.name}
              </Typography>
              {paymentMethodGroup.isExpanded ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </Box>
            {paymentMethodGroup.isExpanded && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1.5,
                  mt: 1.5,
                }}
              >
                {paymentMethodGroup.paymentMethods.map(
                  (paymentMethod, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}
                      >
                        <Box
                          sx={{ width: '24px' }}
                          component='img'
                          src={paymentMethod.imageUrl}
                        />
                        <Typography sx={{ fontSize: '12px' }}>
                          {paymentMethod.name}
                        </Typography>
                      </Box>
                      {permissions.includes(
                        'adjust-outlet-payment-method-status'
                      ) && (
                        <Switch
                          size='small'
                          checked={Boolean(
                            paymentMethod.outletPaymentMethod.status
                          )}
                          onChange={() =>
                            handleChangeStatus(
                              paymentMethod,
                              paymentMethodGroup
                            )
                          }
                        />
                      )}
                    </Box>
                  )
                )}
              </Box>
            )}
          </CustomCard>
        ))}
      </Box>
    </Box>
  );
};
export default PaymentMethodCards;
