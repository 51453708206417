import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getOperatingHours } from '../api/operatingHour';
import OperatingHourCards from '../components/OperatingHourCards';
import CustomAppBar from '../components/shared/CustomAppBar';

const OperatingHour = ({ permissions }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [operatingHours, setOperatingHours] = useState([]);

  const fetchOperatingHours = async () => {
    try {
      setIsLoading(true);
      const { data: operatingHours } = await getOperatingHours();
      setOperatingHours(operatingHours);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOperatingHours();
  }, []);

  return (
    <Box>
      <CustomAppBar title="Operating Hour" />
      <Container maxWidth="lg" disableGutters>
        <Box sx={{ p: 2 }}>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '500px',
                transform: 'scaleX(-1)',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          {!isLoading && operatingHours.length === 0 && (
            <Typography sx={{ fontSize: '0.875rem' }}>
              No operating hour available
            </Typography>
          )}

          {!isLoading && operatingHours.length > 0 && (
            <OperatingHourCards
              operatingHours={operatingHours}
              setOperatingHours={setOperatingHours}
              permissions={permissions}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};
export default OperatingHour;
